<template>
  <div class="animated-bg">
    <div class="page agency" id="agency">
      <svg
        class="agency__logo"
        width="1280"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        style="enable-background: new 0 0 1280 1024"
        xml:space="preserve"
        viewBox="166.85 429.57 947.25 164.19"
      >
        <defs>
          <clipPath id="mob-logo" clipPathUnits="objectBoundingBox">
            <path
              d="M735.2 435.89v12.63h-12.63v12.63H608.9v25.26h50.52v-12.63h12.63v-12.63h50.52v25.26h63.15V524.3h12.63v-88.4l-63.15-.01zm0 75.78v12.63h-12.63v12.63h-25.26V524.3h12.63v-12.63h12.63v-12.63h12.63v12.63zM962.54 454.83V442.2h-12.63v-12.63h-75.78v12.63H861.5v37.89h12.63v12.63h12.63v12.63h12.63v12.63H861.5v12.63h-12.63v-12.63h-12.63v-12.63h-12.63v12.63h12.63v12.63h12.63v12.63h75.78v-12.63h25.26v-25.26h12.63v-37.89h-12.63v-12.63h-12.63v-12.63h-12.63v-12.63h12.63v-12.63h12.63v12.63h12.63v12.63h12.63v-12.63h12.63v-12.63h12.63v-12.63zM621.53 461.15v-12.63H608.9v-12.63H482.6v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h138.93v-12.63h25.26v-12.63h12.63v-12.63h12.63V524.3h-12.63v-12.63h-12.63v-12.63h37.89v-12.63h12.63v-25.26h-12.63zm-75.78 75.78h-12.63v12.63h-37.89v-12.63h12.63V524.3h37.89v12.63zm12.63-50.52v12.63h-37.89v-12.63h12.63v-12.63h37.89v12.63h-12.63zM1013.06 435.89v25.26h-12.63v25.26H987.8v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h113.67v-12.63h12.63v-25.26h-50.52v-12.63h12.63V524.3h37.89v-12.63h12.63v-12.63h-37.89v-12.63h12.63v-12.63h37.89v-12.63h12.63v-25.26zM394.19 435.89v25.26h-12.63v25.26h-12.63v-25.26H356.3v-25.26h-50.52v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h50.52v-25.26h12.63v-25.26h12.63v25.26h12.63v25.26h50.52v-25.26h12.63v-25.26h12.63v-25.26h12.63v-25.26h12.63v-25.26zM255.26 486.41h12.63v-25.26h12.63v-25.26h-63.15v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26H230v-25.26h12.63v-25.26h12.63z"
            />
          </clipPath>
        </defs>
        <g>
          <path
            class="st0"
            d="M735.2,435.89v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63   v12.63h-12.63v12.63h-12.63v12.63H608.9v25.26h50.52v-12.63h12.63v-12.63h50.52v25.26h63.15V524.3h12.63v-88.4L735.2,435.89z    M735.2,511.67v12.63h-12.63v12.63h-25.26V524.3h12.63v-12.63h12.63v-12.63h12.63V511.67z"
          />
          <polygon
            class="st0"
            points="962.54,454.83 962.54,442.2 949.91,442.2 949.91,429.57 874.13,429.57 874.13,442.2 861.5,442.2    861.5,454.83 848.87,454.83 848.87,467.46 836.24,467.46 836.24,505.35 848.87,505.35 848.87,517.98 861.5,517.98 861.5,530.61    874.13,530.61 874.13,543.24 861.5,543.24 861.5,555.87 848.87,555.87 848.87,543.24 836.24,543.24 836.24,530.61 823.61,530.61    823.61,543.24 810.98,543.24 810.98,555.87 798.35,555.87 798.35,568.5 810.98,568.5 810.98,581.13 823.61,581.13 823.61,593.76    899.39,593.76 899.39,581.13 924.65,581.13 924.65,555.87 937.28,555.87 937.28,517.98 924.65,517.98 924.65,505.35 912.02,505.35    912.02,492.72 899.39,492.72 899.39,480.09 912.02,480.09 912.02,467.46 924.65,467.46 924.65,480.09 937.28,480.09 937.28,492.72    949.91,492.72 949.91,480.09 962.54,480.09 962.54,467.46 975.17,467.46 975.17,454.83  "
          />
          <path
            class="st0"
            d="M621.53,461.15v-12.63H608.9v-12.63H482.6v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63   v25.26h138.93v-12.63h25.26v-12.63h12.63v-12.63h12.63V524.3h-12.63v-12.63h-12.63v-12.63h37.89v-12.63h12.63v-25.26H621.53z    M545.75,536.93h-12.63v12.63h-37.89v-12.63h12.63V524.3h12.63h12.63h12.63V536.93z M558.38,486.41v12.63h-12.63h-12.63h-12.63   v-12.63h12.63v-12.63h12.63h12.63h12.63v12.63H558.38z"
          />
          <polygon
            class="st0"
            points="1013.06,435.89 1013.06,461.15 1000.43,461.15 1000.43,486.41 987.8,486.41 987.8,511.67    975.17,511.67 975.17,536.93 962.54,536.93 962.54,562.19 949.91,562.19 949.91,587.45 1063.58,587.45 1063.58,574.82    1076.21,574.82 1076.21,549.56 1025.69,549.56 1025.69,536.93 1038.32,536.93 1038.32,524.3 1076.21,524.3 1076.21,511.67    1088.84,511.67 1088.84,499.04 1050.95,499.04 1050.95,486.41 1063.58,486.41 1063.58,473.78 1101.47,473.78 1101.47,461.15    1114.1,461.15 1114.1,435.89  "
          />
          <g>
            <polygon
              class="st0"
              points="394.19,435.89 394.19,461.15 381.56,461.15 381.56,486.41 368.93,486.41 368.93,461.15 356.3,461.15     356.3,435.89 305.78,435.89 305.78,461.15 293.15,461.15 293.15,486.41 280.52,486.41 280.52,511.67 267.89,511.67 267.89,536.93     255.26,536.93 255.26,562.19 305.78,562.19 305.78,536.93 318.41,536.93 318.41,511.67 331.04,511.67 331.04,536.93     343.67,536.93 343.67,562.19 394.19,562.19 394.19,536.93 406.82,536.93 406.82,511.67 419.45,511.67 419.45,486.41     432.08,486.41 432.08,461.15 444.71,461.15 444.71,435.89   "
            />
            <polygon
              class="st0"
              points="255.26,486.41 267.89,486.41 267.89,461.15 280.52,461.15 280.52,435.89 217.37,435.89     217.37,461.15 204.74,461.15 204.74,486.41 192.11,486.41 192.11,511.67 179.48,511.67 179.48,536.93 166.85,536.93     166.85,562.19 230,562.19 230,536.93 242.63,536.93 242.63,511.67 255.26,511.67   "
            />
          </g>
        </g>
      </svg>
      <div class="container">
        <div class="agency__about">
          <div class="agency__custom-title">
            <div class="lang-agency-title">
              <span v-html="$t('agency.text_1')"></span>
              <div class="agency__about-link">
                <span
                  class="js-open-modal-video"
                  :glitch="$t('other.showreel')"
                  >{{ $t('other.showreel') }}</span
                >
                <video
                  src="@/assets/video/services/Comp_9.mp4"
                  autoplay
                  muted
                  playsinline
                  loop
                  width="408"
                  height="230"
                ></video>
              </div>
              <span v-html="$t('agency.text_2')"></span>
            </div>
          </div>
          <span id="agency-wr-trigger"></span>
          <div class="agency__wr">
            <div class="agency__title">
              <div class="agency__title-frame" id="agency-anim-title">
                <img
                  :src="`/img/agency/title-anim-${$i18n.locale}/0.png`"
                  alt=""
                />
              </div>
            </div>
            <div class="agency__grid">
              <div class="agency__grid-content">
                <div
                  class="agency__grid-content-item agency__grid-content-item--1"
                >
                  <video
                    src="@/assets/video/services/Comp 1.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-1"
                  ></video>
                  <img
                    src="@/assets/img/agency/services/sphere.gif"
                    alt=""
                    class="agency__grid-absolute agency__grid-icn-1"
                  />
                  <h2
                    class="lang-agency-grid-title-1"
                    v-html="$t('agency.title_1')"
                  ></h2>
                  <div>
                    <p
                      class="lang-agency-grid-text-1"
                      v-html="$t('agency.subTitle_1')"
                    ></p>
                  </div>
                </div>
                <div
                  class="agency__grid-content-item agency__grid-content-item--2"
                >
                  <video
                    src="@/assets/video/services/Comp 2.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-2"
                  ></video>
                  <video
                    src="@/assets/video/services/Comp 3.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-3"
                  ></video>
                  <img
                    src="@/assets/img/agency/services/eye.gif"
                    alt=""
                    class="agency__grid-absolute agency__grid-icn-2"
                  />
                  <h2 class="lang-agency-grid-title-2">
                    {{ $t('agency.title_2') }}
                  </h2>
                  <div>
                    <p
                      class="lang-agency-grid-text-2"
                      v-html="$t('agency.subTitle_2')"
                    ></p>
                  </div>
                </div>
                <div
                  class="agency__grid-content-item agency__grid-content-item--3"
                >
                  <video
                    src="@/assets/video/services/Comp 4.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-4"
                  ></video>
                  <video
                    src="@/assets/video/services/Comp 5.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-5"
                  ></video>
                  <img
                    src="@/assets/img/agency/services/heart.gif"
                    alt=""
                    class="agency__grid-absolute agency__grid-icn-3"
                  />
                  <img
                    src="@/assets/img/agency/services/mas.gif"
                    alt=""
                    class="agency__grid-absolute agency__grid-icn-4"
                  />
                  <h2
                    class="lang-agency-grid-title-3"
                    v-html="$t('agency.title_3')"
                  ></h2>
                  <div>
                    <p
                      class="lang-agency-grid-text-3"
                      v-html="$t('agency.subTitle_3')"
                    ></p>
                  </div>
                </div>
                <div
                  class="agency__grid-content-item agency__grid-content-item--4"
                >
                  <video
                    src="@/assets/video/services/Comp 6.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-6"
                  ></video>
                  <video
                    src="@/assets/video/services/Comp 7.mp4"
                    alt=""
                    autoplay
                    muted
                    playsinline
                    loop
                    class="agency__grid-absolute agency__grid-img agency__grid-img-7"
                  ></video>
                  <img
                    src="@/assets/img/agency/services/flash.gif"
                    alt=""
                    class="agency__grid-absolute agency__grid-icn-5"
                  />
                  <h2 class="lang-agency-grid-title-4">
                    {{ $t('agency.title_4') }}
                  </h2>
                  <div>
                    <p
                      class="lang-agency-grid-text-4"
                      v-html="$t('agency.subTitle_4')"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="agency__footer">
        <div class="container">
          <div
            v-html="$t('agency.textBottom')"
            class="agency__footer-text lang-agency-footer"
          ></div>
          <nav class="agency__footer-nav">
            <div class="agency__footer-menu lang-agency-footer-menu">
              <a
                :glitch="$t('other.presentation')"
                class="js-blocked"
                :href="
                  $i18n.locale === 'ru'
                    ? '/docs/inbase2022.pdf'
                    : '/docs/inbase2022_eng.pdf'
                "
                target="_blank"
                >{{ $t('other.presentation') }}</a
              >
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </div>
          </nav>
          <div class="agency__footer-mouse">
            <img src="@/assets/img/agency/mouse.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal__video">
      <div class="modal__video-body">
        <video
          id="agency_video"
          playsinline
          loop
          preload="metadata"
          src="@/assets/video/modal_video_1.mp4"
        ></video>
        <div class="custom__controls">
          <div class="custom__controls-timeline">
            <div class="progress">
              <div class="progress-passed"></div>
            </div>
          </div>
          <div class="custom__controls-buttons">
            <span id="pause">play</span>
            <span id="mute">sound off</span>
            <span id="close">close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    oldCode(this)
  },
}

const oldCode = (self) => {
  const bgController = new ScrollMagic.Controller()
  const textController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '.animated-bg',
    duration: $('.animated-bg').height(),
    offset: 800,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(bgController)

  new ScrollMagic.Scene({
    triggerElement: '.agency__about',
    offset: 500,
    duration: 500,
    reverse: true,
  })
    .on('progress', function (event) {
      let count = event.progress.toFixed(1) * 10
      if (count > 8) count = 8
      $('#agency-anim-title img').attr(
        'src',
        `/img/agency/title-anim-${self.$i18n.locale}/${count}.png`
      )
    })
    .addTo(textController)

  function VideoModalControl(
    modal,
    videoWrapper,
    video,
    control,
    progress,
    play,
    mute,
    close
  ) {
    this.modal = document.querySelector(modal)
    this.videoWrapper = document.querySelector(videoWrapper)
    this.video = document.querySelector(video)
    this.control = document.querySelector(control)
    this.progress = document.querySelector(progress)
    this.btnPlay = document.querySelector(play)
    this.btnMute = document.querySelector(mute)
    this.btnClose = document.querySelector(close)
    this.isActive = false
    this.isLoad = false
    this.isControlVue = false
    this.progressInterval = null
    this.controlTimeOut = null
    this.delay = 3000
    this.scrollY = 0

    this.open = () => {
      this.isActive = true
      this.modal.classList.add('modal__video-active')
      if (!this.isLoad) {
        this.video.load()
        this.isLoad = true
      }
      this.scrollOff()
      this.init()
    }

    this.close = () => {
      this.isActive = false
      this.modal.classList.remove('modal__video-active')
      this.pause()
      this.scrollOn()
      this.destroy()
    }

    this.tugglePlay = () => {
      this.video.paused ? this.play() : this.pause()
    }

    this.play = () => {
      this.video.play()
      this.btnPlay.textContent = 'pause'
    }

    this.pause = () => {
      this.video.pause()
      this.btnPlay.textContent = 'play'
    }

    this.tuggleMuted = () => {
      if (this.video.muted) {
        this.video.muted = false
        this.btnMute.textContent = 'sound\xA0off'
      } else {
        this.video.muted = true
        this.btnMute.textContent = 'sound\xA0on'
      }
    }

    this.controlShow = () => {
      this.control.style.opacity = 1
    }

    this.controlHide = () => {
      this.control.style.opacity = 0
    }

    this.controlTimeShown = () => {
      this.controlShow()
      if (this.controlTimeOut) clearTimeout(this.controlTimeOut)
      if (!this.video.paused)
        this.controlTimeOut = setTimeout(this.controlHide, this.delay)
    }

    this.setInterval = () => {
      this.progressInterval = setInterval(() => {
        this.progress.style.width =
          Math.round((this.video.currentTime / this.video.duration) * 100) + '%'
      })
      this.controlTimeOut = setTimeout(this.controlHide, this.delay)
    }

    this.clearInterval = () => {
      if (this.progressInterval) clearInterval(this.progressInterval)
      if (this.controlTimeOut) clearTimeout(this.controlTimeOut)
    }

    this.scrollOn = () => {
      document.documentElement.classList.remove('is-locked')
      this.modal.removeEventListener('pointermove', this.preventDefault)
      window.scrollTo(0, this.scrollY)
    }

    this.scrollOff = () => {
      this.scrollY = window.scrollY
      document.documentElement.classList.add('is-locked')
      this.modal.addEventListener('pointermove', this.preventDefault)
    }

    this.preventDefault = (e) => {
      e.preventDefault()
    }

    this.init = () => {
      this.video.addEventListener('play', this.setInterval)
      this.video.addEventListener('pause', this.clearInterval)
      this.btnPlay.addEventListener('click', this.tugglePlay)
      this.btnMute.addEventListener('click', this.tuggleMuted)
      this.btnClose.addEventListener('click', this.close)
      this.videoWrapper.addEventListener('mousemove', this.controlTimeShown)
      this.videoWrapper.addEventListener('mouseenter', this.controlShow)
      this.videoWrapper.addEventListener('mouseleave', this.controlHide)
    }

    this.destroy = () => {
      this.clearInterval()
      this.video.removeEventListener('play', this.setInterval)
      this.video.removeEventListener('pause', this.clearInterval)
      this.btnPlay.removeEventListener('click', this.tugglePlay)
      this.btnMute.removeEventListener('click', this.tuggleMuted)
      this.btnClose.removeEventListener('click', this.close)
      this.videoWrapper.removeEventListener('mousemove', this.controlTimeShown)
      this.videoWrapper.removeEventListener('mouseenter', this.controlShow)
      this.videoWrapper.removeEventListener('mouseleave', this.controlHide)
    }
  }

  const videoModalControl = new VideoModalControl(
    '.modal__video',
    '.modal__video-body',
    '.modal__video #agency_video',
    '.modal__video .custom__controls',
    '.modal__video .progress-passed',
    '.modal__video #pause',
    '.modal__video #mute',
    '.modal__video #close'
  )

  window.addEventListener('click', (event) => {
    if (
      event.target.closest('.agency__about-link') &&
      !videoModalControl.isActive
    ) {
      videoModalControl.open()
      return
    }
    if (!event.target.closest('.modal__video') && videoModalControl.isActive) {
      videoModalControl.close()
      return
    }
  })
}
</script>

<style>
.agency {
}
.st0 {
  fill: #cccfc0;
}
</style>

<template>
  <div class="animated-bg">
    <header class="cases-header">
      <div class="container">
        <div class="cases-header__wr">
          <div class="cases-header__col-left">
            <h1 class="cases-header__title">
              <span v-html="$t('france.title')"></span>
              <i>{{ $t('france.desc') }}</i>
            </h1>
            <ul class="cases-header__marks">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="180"
                  height="52"
                  viewBox="0 0 180 52"
                >
                  <path
                    id="creative_copy"
                    data-name="creative copy"
                    d="M3113.55,261.123h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v2.7h-2.7v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.7v2.7h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm26.66,0h-1.36v-1.35h-1.35v-1.35h-1.35v-1.349h-1.34v-1.35h-1.35v8.1h-2.7v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.36v1.349h1.34v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.34v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.34v-2.7h-1.34v-1.349Zm9.11-2.7h12.14v2.7h-9.44v5.4h6.74v2.7h-6.74v5.4h9.44v2.7h-12.14v-18.9Zm29.34,10.8h1.35v8.1h-2.7v-4.049h-8.1v4.049h-2.7v-8.1h1.35v-2.7h1.35v-2.7h1.35v-2.7h1.35v-2.7h2.7v2.7h1.35v2.7h1.35v2.7h1.35v2.7Zm-8.1,1.35h5.4v-2.7h-1.35v-4.05h-2.7v4.05h-1.35v2.7Zm25.27-12.148v2.7h-5.41v16.2h-2.69v-16.2h-5.4v-2.7h13.5Zm7.08,2.7h-2.7v-2.7h8.1v2.7h-2.7v13.5h2.7v2.7h-8.1v-2.7h2.7v-13.5Zm11.79,2.7h-1.35v-5.4h2.7v4.049h1.34v4.05h1.36v4.049h2.7v-4.049h1.34v-4.05h1.35v-4.049h2.7v5.4h-1.35v4.05h-1.35v4.049h-1.35v2.7h-1.34v2.7h-2.7v-2.7h-1.36v-2.7h-1.34v-4.049h-1.35v-4.05Zm17.21-5.4h12.14v2.7h-9.45v5.4h6.75v2.7h-6.75v5.4h9.45v2.7h-12.14v-18.9ZM3113,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm148-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16,16H3113v-4h132v4Zm0-48H3113v-4h132v4Z"
                    transform="translate(-3089 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="220"
                  height="52"
                  viewBox="0 0 220 52"
                >
                  <path
                    id="production_copy"
                    data-name="production copy"
                    d="M3336.34,253.025v1.349h-1.35v1.35h-8.1v8.1h-2.69v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35Zm-2.7-5.4h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm18.43,13.5h-1.35v-1.35h-1.35v-1.35h-1.35v-1.349h-1.35v-1.35h-1.35v8.1h-2.7v-18.9h10.8v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.35v1.349h1.35v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.35v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm10.33,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v13.5h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-10.8h-1.34v-1.349Zm21.15,13.5v1.349h-1.34v1.35h-10.8v-18.9h10.8v1.35h1.34v1.35h1.36v13.5h-1.36Zm-2.69-13.5h-6.75v13.5h6.75v-1.35h1.35v-10.8h-1.35v-1.349Zm10.34,13.5h-1.34v-16.2h2.69v14.847H3402v1.35h5.4v-1.35h1.35V244.926h2.7v16.2h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm18.45,0h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.35v2.7h-2.71v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.71v2.7h-1.35v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm29.92-16.2v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm6.95,2.7h-2.69v-2.7h8.09v2.7h-2.7v13.5h2.7v2.7h-8.09v-2.7h2.69v-13.5Zm11.65,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.1v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.35v-10.8h-1.35v-1.349Zm11.71,16.2h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.349h1.35v2.7h1.35v1.349h1.35v2.7h1.35V244.926h2.7v18.9h-2.7v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v12.148ZM3325,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm188-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3325v-4h172v4Zm0,48H3325v-4h172v4Z"
                    transform="translate(-3301 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="26"
                  viewBox="0 0 48 26"
                >
                  <path
                    id="smm"
                    d="M292.323,339.813h-2.7v0.675h-0.675v1.349h0.675v0.675H293v0.675h0.675v0.675h0.675v2.7h-0.675v0.675H293v0.675h-4.049v-0.675h-0.675v-0.675H287.6v-1.35h1.349v0.675h0.675v0.675h2.7v-0.675H293v-1.349h-0.674v-0.675h-3.375v-0.675h-0.675v-0.675H287.6v-2.7h0.674v-0.675h0.675v-0.675H293v0.675h0.675v0.675h0.675v1.349H293v-0.674h-0.674v-0.675Zm5.972,2.7h-0.674v5.4h-1.35v-9.448h1.35v1.35H298.3v1.349h0.675v1.35h1.35v-1.35H301v-1.349h0.675v-1.35h1.349v9.448H301.67v-5.4H301v1.35H300.32v1.349h-1.35v-1.349H298.3v-1.35Zm8.717,0h-0.674v5.4h-1.35v-9.448h1.35v1.35h0.674v1.349h0.675v1.35h1.349v-1.35h0.676v-1.349h0.674v-1.35h1.35v9.448h-1.35v-5.4h-0.674v1.35h-0.676v1.349h-1.349v-1.349h-0.675v-1.35ZM288,332h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V338h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm32-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V338h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16H288v-2h24v2Zm0,24H288v-2h24v2Z"
                    transform="translate(-276 -330)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="26"
                  viewBox="0 0 102 26"
                >
                  <path
                    id="promotion"
                    d="M221.672,456.512v0.674H221v0.675h-4.048v4.05H215.6v-9.448H221v0.675h0.675v0.675h0.675v2.7h-0.675Zm-1.35-2.7h-3.373v2.7h3.373v-0.675H221v-1.35h-0.675v-0.674Zm9.32,6.749h-0.675v-0.675h-0.674v-0.675h-0.674v-0.675h-0.676v-0.676h-0.674v4.05h-1.35v-9.448h5.4v0.675h0.674v0.675h0.676v2.7h-0.676v0.674h-0.674v0.675h-1.35v0.676h0.675v0.675h0.675v0.675h0.674v0.675h0.676v1.349h-1.35v-0.675h-0.675v-0.674Zm0-6.749h-3.373v2.7h3.373v-0.675h0.675v-1.35h-0.675v-0.674Zm5.272,6.749h-0.675v-6.749h0.675v-0.675h0.675v-0.675h4.05v0.675h0.674v0.675h0.676v6.749h-0.676v0.674h-0.674v0.675h-4.05v-0.675h-0.675v-0.674Zm4.05-6.749h-2.7v0.674h-0.675v5.4h0.675v0.675h2.7v-0.675h0.675v-5.4h-0.675v-0.674Zm6.639,2.7h-0.675v5.4h-1.349v-9.448h1.349v1.35H245.6v1.35h0.675v1.349h1.349v-1.349H248.3v-1.35h0.675v-1.35h1.349v9.448h-1.349v-5.4H248.3v1.349h-0.676v1.351h-1.349v-1.351H245.6v-1.349Zm7.99,4.05h-0.675v-6.749h0.675v-0.675h0.675v-0.675h4.048v0.675h0.676v0.675h0.674v6.749h-0.674v0.674h-0.676v0.675h-4.048v-0.675h-0.675v-0.674Zm4.049-6.749h-2.7v0.674h-0.675v5.4h0.675v0.675h2.7v-0.675h0.674v-5.4h-0.674v-0.674Zm11.015-1.35v1.35h-2.7v8.1h-1.35v-8.1h-2.7v-1.35h6.746Zm3.587,1.35H270.9v-1.35h4.048v1.35h-1.35v6.749h1.35v1.349H270.9v-1.349h1.349v-6.749Zm5.93,6.749H277.5v-6.749h0.673v-0.675h0.676v-0.675H282.9v0.675h0.676v0.675h0.674v6.749h-0.674v0.674H282.9v0.675H278.85v-0.675h-0.676v-0.674Zm4.051-6.749h-2.7v0.674H278.85v5.4h0.676v0.675h2.7v-0.675H282.9v-5.4h-0.674v-0.674Zm5.965,8.1h-1.351v-9.448h1.351v0.675h0.675v1.349h0.675v0.676h0.674v1.349h0.675v0.674h0.674v1.351h0.676v-6.074h1.349v9.448h-1.349v-0.675h-0.676v-1.349h-0.674v-0.675h-0.675v-1.351H289.54v-0.675h-0.675v-1.349H288.19v6.074ZM216,446h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V452h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm86-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V452h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16H216v-2h78v2Zm0,24H216v-2h78v2Z"
                    transform="translate(-204 -444)"
                  />
                </svg>
              </li>
            </ul>
            <ul class="cases-header__nums">
              <li>
                <b>3,5</b>
                <span>%</span>
                <p>ER</p>
              </li>
              <li>
                <b>+43</b>
                <span>%</span>
                <p>{{ $t('other.subscribers') }}</p>
              </li>
            </ul>
          </div>
          <div class="cases-header__col-right">
            <div class="cases-header__desc" v-html="$t('france.text_1')"></div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div id="gallery" v-show="false">
        <section class="france-gallery">
          <div class="container">
            <div class="france-gallery__item-header">
              <p class="france-gallery__item-header-text"></p>
              <div class="france-gallery__item-controls">
                <button data-gallery="boat">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="64"
                    viewBox="0 0 72 64"
                  >
                    <path
                      d="M3393,923h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm16,0h-8v-4h8v4Zm8-4h-8v-4h8v4Zm8,4h-8v-4h8v4Zm8-4h-8v-4h8v4Zm8,4h-8v-4h8v4Zm8-4h-8v-4h8v4Zm8,4h-8v-4h8v4Zm-64-8h-4v-4h4v4Zm-4-4h-4v-4h4v4Zm60,4h4v-4h-4v4Zm4-4h4v-4h-4v4Zm0,0h-32v-4h32v4Zm-36,0h-28v-4h28v4Zm4-4h-4V863h4v48Zm28-4h-28v-4h28v4Zm-28-36h4v-4h-4v4Zm4,4h4v-4h-4v4Zm4,4h4v-4h-4v4Zm4,4h4v-4h-4v4Zm4,8h4v-8h-4v8Zm4,8h4v-8h-4v8Zm4,8h4v-8h-4v8Zm4,12h-56v-4h56v4Z"
                      transform="translate(-3377 -863)"
                    />
                  </svg>
                </button>
                <button data-gallery="castle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="96"
                    height="60"
                    viewBox="0 0 96 60"
                  >
                    <path
                      d="M3519,875h-4v44h4V875Zm16,0h-4v44h4V875Zm-12-4h-4v-4h4v4Zm-8,0h-4v-4h4v4Zm16,0h-4v-4h4v4Zm8,0h-4v-4h4v4Zm0,4h-28v-4h28v4Zm48,0h-4v44h4V875Zm16,0h-4v44h4V875Zm-20-4h-4v-4h4v4Zm8,0h-4v-4h4v4Zm8,0h-4v-4h4v4Zm8,0h-4v-4h4v4Zm0,4h-28v-4h28v4Zm-48,28h-4v-4h4v4Zm6,4h-14v-4h14v4Zm-6-8h4v4h-4v-4Zm4,4h4v4h-4v-4Zm6,20h-42v-4h42v4Zm22,0h-24v-4h24v4Zm-20,4h-24v-4h24v4Zm-2-8h-22V907h22v12Zm-2,0h4V907h-4v12Zm-24-28h-4v-4h4v4Zm-4-4h-4v-4h4v4Zm16,4h-4v-4h4v4Zm12,0h-4v-4h4v4Zm-8-4h-4v-4h4v4Zm4,0h-4v-4h4v4Zm16,4h-4v-4h4v4Zm4-4h-4v-4h4v4Zm-32,0h-8v-4h8v4Zm24,0h-8v-4h8v4Zm-48,0h-4v-4h4v4Zm0,12h-4v-4h4v4Zm68-12h-4v-4h4v4Zm0,12h-4v-4h4v4Zm-56,0h4v4h-4v-4Zm40,0h-4v4h4v-4Z"
                      transform="translate(-3511 -867)"
                    />
                  </svg>
                </button>
                <button data-gallery="cheese">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="64"
                    viewBox="0 0 56 64"
                  >
                    <path
                      d="M3721,923h-4V911h4v12Zm0-20h-4v-8h4v8Zm-52,0h-4V875h4v28Zm28-36h-8v-4h8v4Zm0,4h-20v-4h20v4Zm4,4h-32v-4h32v4Zm12,12h-20v-4h20v4Zm-4-4h-24v-4h24v4Zm-4-4h-36v-4h36v4Zm-4-8h-4v-4h4v4Zm0,0h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm0,4h4v4h-4v-4Zm0,0h-16v-4h16v4Zm-40,16h-8v-4h8v4Zm0,0h8v4h-8v-4Zm8,4h8v4h-8v-4Zm8,4h8v4h-8v-4Zm8,4h8v4h-8v-4Zm16-24h-8v-4h8v4Zm0,32h-8v-4h8v4Zm0-16h-4v-8h4v8Zm-20-12h-4v-4h4v4Zm-16-8h-4v-4h4v4Zm4,12h-4v-4h4v4Zm20,4h-4v-4h4v4Z"
                      transform="translate(-3665 -863)"
                    />
                  </svg>
                </button>
                <button data-gallery="mountains">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="60"
                    viewBox="0 0 120 60"
                  >
                    <path
                      d="M3844,900h12v-4h-12v4Zm-4,4h4v-4h-4v4Zm8-8h4v-4h-4v4Zm-16-20h4v-4h-4v4Zm24,20h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm-36-36h4v4h-4v-4Zm28,28h-4v-4h4v4Zm-16-20h-4v4h4v-4Zm4,4h-4v4h4v-4Zm-4,28h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm32-4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h-4v4h4v-4Zm-52-56h-4v4h4v-4Zm0,8h-4v4h4v-4Zm0,4h4v4h-4v-4Zm-4,4h-4v-4h4v4Zm0-8h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm8-4h-4v-4h4v4Zm-16,8h4v-4h-4v4Zm8,4h-4v-4h4v4Zm-28,12h4v4h-4v-4Zm-4-4h4v4h-4v-4Zm8,8h4v4h-4v-4Zm4,4h4v4h-4v-4Zm-12-4h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm48-32h-4v-4h4v4Zm-4,16h-4v-4h4v4Zm20,12h-4v-4h4v4Zm8,4h-4v-4h4v4Zm-12,4h-4v-4h4v4Zm-40-20h4v-4h-4v4Zm0,4h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm-20,12h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm68-36h4v4h-4v-4Zm-28,0h-4v4h4v-4Zm-12,16h-4v-4h4v4Zm44-12h4v4h-4v-4Zm-28,4h-4v-4h4v4Zm20,0h-4v-4h4v4Zm-64,32h-4v-4h4v4Zm20-20h-4v-4h4v4Zm0,8h-4v-4h4v4Zm8,8h-4v-4h4v4Zm-12-4h-4v-4h4v4Zm-4,12h-12v-4h12v4Zm12-4h-12v-4h12v4Zm0,0h12v4h-12v-4Zm24,0h-12v-4h12v4Zm0,0h12v4h-12v-4Zm24,0h-12v-4h12v4Zm0,0h12v4h-12v-4Zm24,0h-12v-4h12v4Zm8,0h-8v4h8v-4Zm-108,4h-4v-4h4v4Zm116,0h-4v-4h4v4Zm-112,0h-4v-4h4v4Zm52-48h-4v-4h4v4Zm-4,20h-4v-4h4v4Zm-8,0h-4v-4h4v4Z"
                      transform="translate(-3768 -868)"
                    />
                  </svg>
                </button>
                <button data-gallery="bike">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="76"
                    height="48"
                    viewBox="0 0 76 48"
                  >
                    <path
                      d="M3954,903h-12v-4h12v4Zm-16,16h-4V907h4v12Zm4-12h-4v-4h4v4Zm16,12h4V907h-4v12Zm-4-12h4v-4h-4v4Zm-12,16h12v4h-12v-4Zm12-4h4v4h-4v-4Zm-12,0h-4v4h4v-4Zm60-16h-12v-4h12v4Zm-16,16h-4V907h4v12Zm4-12h-4v-4h4v4Zm16,12h4V907h-4v12Zm-4-12h4v-4h-4v4Zm-12,16h12v4h-12v-4Zm12-4h4v4h-4v-4Zm-12,0h-4v4h4v-4Zm-16-4h-28v-4h28v4Zm24,0h-4v-4h4v4Zm-4-4h-4v-4h4v4Zm-8-8h-4v-4h4v4Zm0-4h-8v-4h8v4Zm-4-4h4v-4h-4v4Zm-4-12h12v-4h-12v4Zm12,4h-4v4h4v-4Zm4-4h-4v4h4v-4Zm-44,28h-4v-4h4v4Zm12-8h-4v-4h4v4Zm0-4h16v-4h-16v4Zm-4,12h-8v-4h8v4Zm16,0h-12v-4h12v4Zm8-4h-4v-4h4v4Zm-4,4h-4v-4h4v4Zm-12-16h-4v-4h4v4Zm-12-4h-4v-4h4v4Zm16,0h-16v-4h16v4Z"
                      transform="translate(-3934 -879)"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="france-gallery__wr" style="display: none">
              <div class="france-gallery__item">
                <div class="france-gallery__gallery">
                  <div class="swiper">
                    <div class="swiper-wrapper"></div>
                    <div class="swiper-arrows">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                        >
                          <path
                            id="Shape_674_copy"
                            data-name="Shape 674 copy"
                            d="M1689,1719h4v4h-4v-4Zm4,4h4v4h-4v-4Zm4,4h4v4h-4v-4Zm-4-8h4v-4h-4v4Zm4-4h4v-4h-4v4Z"
                            transform="translate(-1689 -1711)"
                          />
                        </svg>
                      </button>
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                        >
                          <path
                            d="M1731,1723h-4v-4h4v4Zm-4-4h-4v-4h4v4Zm-4-4h-4v-4h4v4Zm4,8h-4v4h4v-4Zm-4,4h-4v4h4v-4Z"
                            transform="translate(-1719 -1711)"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="france-text">
        <div class="container">
          <div class="france-text__wr">
            <h2 v-html="$t('france.subTitle')"></h2>
            <p v-html="$t('france.text_2')"></p>
          </div>
        </div>
      </section>
      <section class="france-video">
        <div class="video-block js-video-block">
          <video
            src="@/assets/video/alps.mp4"
            autoplay
            loop
            playsinline
            muted
            width="650"
            height="365"
          ></video>
          <div class="video-block__controls">
            <div class="video-block__controls-timeline">
              <!-- <span id="current-time">00:00</span>
							<span id="total-time">01:00</span> -->
              <div class="progress">
                <div class="progress-passed"></div>
              </div>
            </div>
            <div class="video-block__controls-buttons">
              <span id="play-pause">play</span>
              <span id="mute-unmute">sound off</span>
              <!-- <span id="close" class="hidden">close</span> -->
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="cases-footer">
      <div class="container">
        <div class="cases-footer__wr">
          <ul class="cases-footer__menu">
            <li>
              <router-link :glitch="$t('menu.agency')" to="/agency">{{
                $t('menu.agency')
              }}</router-link>
            </li>
            <li class="lang-offer-menu-2 js-lang-offer-menu">
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
            </li>
            <li>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </li>
          </ul>
          <img src="@/assets/img/agency/mouse.gif" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css'

export default {
  mounted() {
    oldCode(this)
  },
}

const oldCode = (self) => {
  const bgController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '.animated-bg',
    duration: $('.animated-bg').height(),
    offset: 800,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(bgController)

  const gallery = {
    boat: [
      {
        image: require('@/assets/img/france/gallery/boat/023.jpg'),
        desc: self.$t("france.gallery['23']"),
      },
      {
        image: require('@/assets/img/france/gallery/boat/005.jpg'),
        desc: self.$t("france.gallery['5']"),
      },
      {
        image: require('@/assets/img/france/gallery/boat/004.jpg'),
        desc: self.$t("france.gallery['4']"),
      },
      {
        image: require('@/assets/img/france/gallery/boat/003.jpg'),
        desc: self.$t("france.gallery['3']"),
      },
      {
        image: require('@/assets/img/france/gallery/boat/002.jpg'),
        desc: self.$t("france.gallery['2']"),
      },
    ],
    castle: [
      {
        image: require('@/assets/img/france/gallery/castle/006.jpg'),
        desc: self.$t("france.gallery['6']"),
      },
      {
        image: require('@/assets/img/france/gallery/castle/007.jpg'),
        desc: self.$t("france.gallery['7']"),
      },
      {
        image: require('@/assets/img/france/gallery/castle/008.jpg'),
        desc: self.$t("france.gallery['8']"),
      },
      {
        image: require('@/assets/img/france/gallery/castle/009.jpg'),
        desc: self.$t("france.gallery['9']"),
      },
    ],
    cheese: [
      {
        image: require('@/assets/img/france/gallery/cheese/010.jpg'),
        desc: self.$t("france.gallery['10']"),
      },
      {
        image: require('@/assets/img/france/gallery/cheese/011.jpg'),
        desc: self.$t("france.gallery['11']"),
      },
      {
        image: require('@/assets/img/france/gallery/cheese/012.jpg'),
        desc: self.$t("france.gallery['12']"),
      },
      {
        image: require('@/assets/img/france/gallery/cheese/013.jpg'),
        desc: self.$t("france.gallery['13']"),
      },
    ],
    mountains: [
      {
        image: require('@/assets/img/france/gallery/mountains/014.jpg'),
        desc: self.$t("france.gallery['14']"),
      },
      {
        image: require('@/assets/img/france/gallery/mountains/015.jpg'),
        desc: self.$t("france.gallery['15']"),
      },
      {
        image: require('@/assets/img/france/gallery/mountains/016.jpg'),
        desc: self.$t("france.gallery['16']"),
      },
      {
        image: require('@/assets/img/france/gallery/mountains/017.jpg'),
        desc: self.$t("france.gallery['17']"),
      },
      {
        image: require('@/assets/img/france/gallery/mountains/018.jpg'),
        desc: self.$t("france.gallery['18']"),
      },
      {
        image: require('@/assets/img/france/gallery/mountains/020.jpg'),
        desc: self.$t("france.gallery['20']"),
      },
    ],
    bike: [
      {
        image: require('@/assets/img/france/gallery/bike/022.jpg'),
        desc: self.$t("france.gallery['22']"),
      },
      {
        image: require('@/assets/img/france/gallery/bike/024.jpg'),
        desc: self.$t("france.gallery['24']"),
      },
      {
        image: require('@/assets/img/france/gallery/bike/025.jpg'),
        desc: self.$t("france.gallery['25']"),
      },
      {
        image: require('@/assets/img/france/gallery/bike/026.jpg'),
        desc: self.$t("france.gallery['26']"),
      },
      {
        image: require('@/assets/img/france/gallery/bike/027.jpg'),
        desc: self.$t("france.gallery['27']"),
      },
    ],
  }
  let galleryTitlesCount = 0
  const galleryTitles = [
    self.$t('france.question_1'),
    self.$t('france.question_2'),
    self.$t('france.question_3'),
    self.$t('france.question_4'),
    self.$t('france.question_5'),
    '',
  ]

  const opened = []

  function createNewGallery() {
    const template = document.querySelector('#gallery .france-gallery')
    // console.log(template)
    const clone = template.cloneNode(true)
    $('.france-text').before(clone)
    opened.forEach((elem) => {
      $('.france-gallery').last().find(`[data-gallery=${elem}]`).remove()
    })
  }

  function setHandlers() {
    const galleryBlock = $('.france-gallery').last()
    galleryBlock
      .find('.france-gallery__item-header-text')
      .html(galleryTitles[galleryTitlesCount])
    galleryBlock
      .find('.france-gallery__item-controls button')
      .on('click', function (e) {
        const galleryType = $(this).attr('data-gallery')
        opened.push(galleryType)
        $(this).addClass('disabled')
        galleryBlock.find('.france-gallery__wr').show()
        galleryBlock
          .find('.france-gallery__item-controls button')
          .css({ 'pointer-events': 'none' })
        gallery[galleryType].forEach((element) => {
          $(this)
            .closest('.france-gallery')
            .find('.swiper-wrapper')
            .append(
              `<div class="swiper-slide">
							<img src="${element.image}">
							<p>${element.desc}</p>
						</div>`
            )
        })
        const galleryElem = galleryBlock.find('.swiper')[0]
        const slider = new Swiper(galleryElem, {
          autoHeight: true,
          on: {
            init: function () {
              galleryElem
                .querySelector('.swiper-arrows button:last-child')
                .addEventListener('click', () => {
                  slider.slideNext()
                })
              galleryElem
                .querySelector('.swiper-arrows button:first-child')
                .addEventListener('click', () => {
                  slider.slidePrev()
                })
            },
          },
        })
        if (opened.length !== Object.keys(gallery).length) {
          createNewGallery()
          setHandlers()
        }
      })
    galleryTitlesCount++
  }

  createNewGallery()
  setHandlers()

  $('.js-video-block').each((index, item) => {
    const video = $(item).find('video')[0]
    const playBtn = $(item).find('#play-pause')
    const controls = $(item).find('.video-block__controls')
    const muteBtn = $(item).find('#mute-unmute')
    const progressBar = $(item).find('.progress-passed')
    let videoPlayed = $(video).attr('autoplay')
    let videoMuted = $(video).attr('muted')
    let showControlsTimer
    playBtn.html(videoPlayed ? 'pause' : 'play')
    muteBtn.html(videoMuted ? 'sound off' : 'sound on')

    $(item).on({
      mouseenter: function () {
        controls.fadeIn(300)
      },
      mouseleave: function () {
        controls.fadeOut(300)
        clearTimeout(showControlsTimer)
      },
    })

    $(item).mousemove(function (event) {
      controls.fadeIn(300)
      clearTimeout(showControlsTimer)
      showControlsTimer = setTimeout(() => {
        controls.fadeOut(300)
      }, 2000)
    })

    playBtn.on('click', function (e) {
      if (!videoPlayed) {
        videoPlayed = true
        video.play()
      } else {
        videoPlayed = false
        video.pause()
      }
      playBtn.html(videoPlayed ? 'pause' : 'play')
    })

    muteBtn.on('click', function (e) {
      videoMuted = !videoMuted
      muteBtn.html(videoMuted ? 'sound off' : 'sound on')
      video.muted = !video.muted
    })

    $(video).on('timeupdate', function (event) {
      const current = this.currentTime
      const percent = this.duration / 100
      const progress = (current / percent).toFixed()
      progressBar.css('width', progress + '%')
    })
  })
}
</script>

<style></style>

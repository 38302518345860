<template>
  <div class="animated-bg">
    <header class="cases-header">
      <div class="container">
        <div class="cases-header__wr">
          <div class="cases-header__col-left">
            <h1 class="cases-header__title">
              <span v-html="$t('hugo.title')"></span>
              <i>{{ $t('hugo.desc') }}</i>
            </h1>
            <ul class="cases-header__marks">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="26"
                  viewBox="0 0 102 26"
                >
                  <path
                    id="community"
                    d="M1896.27,306.561h-0.67v-6.748h0.67v-0.675h0.68v-0.674H1901v0.674h0.67v0.675h0.68v1.35H1901v-0.675h-0.68v-0.675h-2.7v0.675h-0.67v5.4h0.67v0.674h2.7v-0.674H1901v-0.675h1.35v1.349h-0.68v0.676H1901v0.674h-4.05v-0.674h-0.68v-0.676Zm9.38,0h-0.68v-6.748h0.68v-0.675h0.67v-0.674h4.05v0.674h0.68v0.675h0.67v6.748h-0.67v0.676h-0.68v0.674h-4.05v-0.674h-0.67v-0.676Zm4.05-6.748H1907v0.675h-0.68v5.4H1907v0.674h2.7v-0.674h0.67v-5.4h-0.67v-0.675Zm6.69,2.7h-0.67v5.4h-1.35v-9.447h1.35v1.349h0.67v1.35h0.68v1.349h1.34v-1.349h0.68v-1.35h0.67v-1.349h1.35v9.447h-1.35v-5.4h-0.67v1.35h-0.68v1.35h-1.34v-1.35h-0.68v-1.35Zm9.42,0h-0.68v5.4h-1.34v-9.447h1.34v1.349h0.68v1.35h0.67v1.349h1.35v-1.349h0.68v-1.35h0.67v-1.349h1.35v9.447h-1.35v-5.4h-0.67v1.35h-0.68v1.35h-1.35v-1.35h-0.67v-1.35Zm8.06,4.049h-0.67v-8.1h1.35v7.423h0.67v0.674h2.7v-0.674h0.68v-7.423h1.35v8.1h-0.68v0.676h-0.67v0.674h-4.05v-0.674h-0.68v-0.676Zm10.09,1.35h-1.35v-9.447h1.35v0.674h0.67v1.35h0.68v0.675h0.67v1.349h0.68v0.675h0.67v1.35h0.68v-6.073h1.35v9.447h-1.35v-0.674h-0.68v-1.35h-0.67v-0.675h-0.68v-1.35h-0.67v-0.675h-0.68v-1.35h-0.67v6.074Zm9.37-8.1h-1.35v-1.349h4.05v1.349h-1.35v6.748h1.35v1.35h-4.05v-1.35h1.35v-6.748Zm11.73-1.349v1.349h-2.7v8.1h-1.35v-8.1h-2.69v-1.349h6.74Zm2.97,2.024h-0.68v-2.024h1.35v1.349h0.68v1.35h0.67v1.349h1.35v-1.349h0.68v-1.35h0.67v-1.349h1.35v2.024h-0.67v1.349h-0.68v1.35h-0.67v1.35h-0.68v3.374h-1.35v-3.374h-0.67v-1.35h-0.68v-1.35h-0.67v-1.349ZM1896,292h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V298h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm86-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V298h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16h-78v-2h78v2Zm0,24h-78v-2h78v2Z"
                    transform="translate(-1884 -290)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="82"
                  height="26"
                  viewBox="0 0 82 26"
                >
                  <path
                    id="casting"
                    d="M268.274,306.561H267.6v-6.748h0.676v-0.675h0.674v-0.674H273v0.674h0.675v0.675h0.675v1.35H273v-0.675h-0.675v-0.675h-2.7v0.675h-0.676v5.4h0.676v0.675h2.7v-0.675H273v-0.674h1.35v1.349h-0.675v0.675H273v0.675h-4.049v-0.675h-0.674v-0.675Zm14.556-2.7h0.675v4.049h-1.35v-2.025h-4.048v2.025h-1.35v-4.049h0.676v-1.35h0.674v-1.349h0.674v-1.35h0.675v-1.349h1.349v1.349h0.675v1.35h0.675v1.349h0.675v1.35Zm-4.049.675h2.7v-1.35h-0.675v-2.024h-1.349v2.024h-0.675v1.35Zm11.859-4.724h-2.7v0.675h-0.675v1.35h0.675v0.674h3.374v0.675h0.676v0.675h0.674v2.7H291.99v0.675h-0.676v0.675h-4.049v-0.675H286.59v-0.675h-0.675v-1.349h1.35v0.674h0.675v0.675h2.7v-0.675h0.674v-1.349H290.64v-0.675h-3.375v-0.675H286.59v-0.675h-0.675v-2.7h0.675v-0.675h0.675v-0.674h4.049v0.674h0.676v0.675h0.674v1.35h-1.35v-0.675H290.64v-0.675Zm10.854-1.349v1.349h-2.7v8.1h-1.349v-8.1h-2.7v-1.349h6.748Zm3.423,1.349h-1.35v-1.349h4.05v1.349h-1.35v6.748h1.35v1.35h-4.05v-1.35h1.35v-6.748Zm6.446,8.1h-1.35v-9.447h1.35v0.674h0.674v1.35h0.676v0.675h0.674v1.349h0.674v0.675h0.676v1.35h0.674v-6.073h1.349v9.447h-1.349v-0.675h-0.674v-1.35h-0.676v-0.674h-0.674v-1.35h-0.674v-0.675h-0.676v-1.349h-0.674v6.073Zm8.5-1.35H319.19v-6.748h0.674v-0.675h0.676v-0.674h4.048v0.674h0.675v0.675h0.674v1.35h-1.349v-0.675h-0.674v-0.675h-2.7v0.675H320.54v5.4h0.674v0.675h2.7v-0.675h0.674v-2.024h-2.024v-1.35h3.373v4.049h-0.674v0.675h-0.675v0.675H320.54v-0.675h-0.676v-0.675ZM268,292h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V298h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm66-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V298h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8,8H268v-2h58v2Zm0-24H268v-2h58v2Z"
                    transform="translate(-256 -290)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="220"
                  height="52"
                  viewBox="0 0 220 52"
                >
                  <path
                    id="production_copy"
                    data-name="production copy"
                    d="M3336.34,253.025v1.349h-1.35v1.35h-8.1v8.1h-2.69v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35Zm-2.7-5.4h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm18.43,13.5h-1.35v-1.35h-1.35v-1.35h-1.35v-1.349h-1.35v-1.35h-1.35v8.1h-2.7v-18.9h10.8v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.35v1.349h1.35v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.35v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm10.33,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v13.5h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-10.8h-1.34v-1.349Zm21.15,13.5v1.349h-1.34v1.35h-10.8v-18.9h10.8v1.35h1.34v1.35h1.36v13.5h-1.36Zm-2.69-13.5h-6.75v13.5h6.75v-1.35h1.35v-10.8h-1.35v-1.349Zm10.34,13.5h-1.34v-16.2h2.69v14.847H3402v1.35h5.4v-1.35h1.35V244.926h2.7v16.2h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm18.45,0h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.35v2.7h-2.71v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.71v2.7h-1.35v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm29.92-16.2v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm6.95,2.7h-2.69v-2.7h8.09v2.7h-2.7v13.5h2.7v2.7h-8.09v-2.7h2.69v-13.5Zm11.65,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.1v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.35v-10.8h-1.35v-1.349Zm11.71,16.2h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.349h1.35v2.7h1.35v1.349h1.35v2.7h1.35V244.926h2.7v18.9h-2.7v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v12.148ZM3325,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm188-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3325v-4h172v4Zm0,48H3325v-4h172v4Z"
                    transform="translate(-3301 -228)"
                  />
                </svg>
              </li>
            </ul>
            <ul class="cases-header__nums">
              <li>
                <b>1,5</b>
                <span>{{ $t('other.million') }}</span>
                <p>{{ $t('other.coverage') }}</p>
              </li>
              <li>
                <b>6</b>
                <span>%</span>
                <p>ER</p>
              </li>
            </ul>
          </div>
          <div class="cases-header__col-right">
            <div class="cases-header__desc" v-html="$t('hugo.text_1')"></div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section class="hugo-persons">
        <div class="container">
          <div class="hugo-persons__wr">
            <div class="hugo-persons__item">
              <img
                src="@/assets/img/hugo/dasha.png"
                class="hugo-persons__item-image"
                width="120"
                height="120"
              />
              <h5 v-html="$t('hugo.name_1.name')"></h5>
              <p>{{ $t('hugo.name_1.role') }}</p>
            </div>
            <div class="hugo-persons__item">
              <img
                src="@/assets/img/hugo/eva.png"
                class="hugo-persons__item-image"
                width="120"
                height="120"
              />
              <h5 v-html="$t('hugo.name_2.name')"></h5>
              <p>{{ $t('hugo.name_2.role') }}</p>
            </div>
            <div class="hugo-persons__item">
              <img
                src="@/assets/img/hugo/roma.png"
                class="hugo-persons__item-image"
                width="120"
                height="120"
              />
              <h5 v-html="$t('hugo.name_3.name')"></h5>
              <p>{{ $t('hugo.name_3.role') }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="hugo-offer">
        <div class="container">
          <div class="hugo-offer__wr">
            <h1 v-html="$t('hugo.subTitle_1')"></h1>
          </div>
          <div class="audio-block js-audio-block">
            <audio controls src="@/assets/video/hugo/hugo_track.mp3"></audio>
            <div class="audio-block__wr">
              <div class="audio-block__header">
                <span class="audio-block__header-time">0:00</span>
                <div class="audio-block__header-name">
                  <p>Nostalgic weather</p>
                  <span>128 kbps</span>
                  <span>44.1 kHz</span>
                </div>
                <div class="audio-block__header-type">
                  <span>mono</span>
                  <span>stereo</span>
                </div>
              </div>
              <div class="audio-block__controls">
                <div class="audio-block__buttons">
                  <button class="audio-block__controls-pause">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                    >
                      <path
                        id="pause"
                        d="M2051,1093h4v-20h-4v20Zm-12,0h4v-20h-4v20Z"
                        transform="translate(-2039 -1073)"
                      />
                    </svg>
                  </button>
                  <button class="audio-block__controls-play">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="28"
                      viewBox="0 0 16 28"
                    >
                      <path
                        d="M2098,1081v-4h-4v-4h-4v-4h-4v28h4v-4h4v-4h4v-4h4v-4h-4Z"
                        transform="translate(-2086 -1069)"
                      />
                    </svg>
                  </button>
                </div>
                <div class="audio-block__progress">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="hugo-grid">
        <div class="container">
          <div class="hugo-grid__wr">
            <div class="hugo-grid__item hugo-grid__item--1">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_1.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="271"
                height="326"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--2">
              <video
                class="lazy"
                src="@/assets/video/hugo/offer.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="274"
                height="342"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--3">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_2.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="633"
                height="356"
              ></video>
            </div>
          </div>
          <div class="hugo-grid__wr hugo-grid__wr--second">
            <div class="hugo-grid__item hugo-grid__item--4">
              <div class="hugo-grid__item-text">
                <b v-html="$t('hugo.subTitle_2')"></b>
                <p v-html="$t('hugo.subText_2')"></p>
              </div>
            </div>
            <div class="hugo-grid__item hugo-grid__item--5">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_3.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="288"
                height="391"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--6">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_4.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="288"
                height="391"
              ></video>
            </div>
          </div>
          <div class="hugo-grid__wr hugo-grid__wr--third">
            <div class="hugo-grid__item hugo-grid__item--7">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_5.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="633"
                height="356"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--8">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_6.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="633"
                height="356"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--9">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_7.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="310"
                height="248"
              ></video>
            </div>
            <div class="hugo-grid__item hugo-grid__item--10">
              <video
                class="lazy"
                src="@/assets/video/hugo/hugo_8.mp4"
                alt=""
                autoplay
                muted
                playsinline
                loop
                width="228"
                height="391"
              ></video>
            </div>
          </div>
        </div>
      </section>
      <section class="hugo-video">
        <div class="video-block js-video-block">
          <video
            src="@/assets/video/hugo/hugo_main.mp4"
            autoplay
            loop
            playsinline
            muted
            width="650"
            height="365"
          ></video>
          <div class="video-block__controls">
            <div class="video-block__controls-timeline">
              <!-- <span id="current-time">00:00</span>
							<span id="total-time">01:00</span> -->
              <div class="progress">
                <div class="progress-passed"></div>
              </div>
            </div>
            <div class="video-block__controls-buttons">
              <span id="play-pause">play</span>
              <span id="mute-unmute">sound off</span>
              <!-- <span id="close" class="hidden">close</span> -->
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="cases-footer">
      <div class="container">
        <div class="cases-footer__wr">
          <ul class="cases-footer__menu">
            <li>
              <router-link :glitch="$t('menu.agency')" to="/agency">{{
                $t('menu.agency')
              }}</router-link>
            </li>
            <li class="lang-offer-menu-2 js-lang-offer-menu">
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
            </li>
            <li>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </li>
          </ul>
          <img src="@/assets/img/agency/mouse.gif" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import '@/assets/js/jquery-ui-grab.min.js'

export default {
  mounted() {
    oldCode()
  },
}

const oldCode = () => {
  const bgController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '.animated-bg',
    duration: $('.animated-bg').height(),
    offset: 800,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(bgController)

  $('.js-video-block').each((index, item) => {
    const video = $(item).find('video')[0]
    const playBtn = $(item).find('#play-pause')
    const controls = $(item).find('.video-block__controls')
    const muteBtn = $(item).find('#mute-unmute')
    const progressBar = $(item).find('.progress-passed')
    let videoPlayed = $(video).attr('autoplay')
    let videoMuted = $(video).attr('muted')
    let showControlsTimer
    playBtn.html(videoPlayed ? 'pause' : 'play')
    muteBtn.html(videoMuted ? 'sound off' : 'sound on')

    $(item).on({
      mouseenter: function () {
        controls.fadeIn(300)
      },
      mouseleave: function () {
        controls.fadeOut(300)
        clearTimeout(showControlsTimer)
      },
    })

    $(item).mousemove(function (event) {
      controls.fadeIn(300)
      clearTimeout(showControlsTimer)
      showControlsTimer = setTimeout(() => {
        controls.fadeOut(300)
      }, 2000)
    })

    playBtn.on('click', function (e) {
      if (!videoPlayed) {
        videoPlayed = true
        video.play()
      } else {
        videoPlayed = false
        video.pause()
      }
      playBtn.html(videoPlayed ? 'pause' : 'play')
    })

    muteBtn.on('click', function (e) {
      videoMuted = !videoMuted
      muteBtn.html(videoMuted ? 'sound off' : 'sound on')
      video.muted = !video.muted
    })

    $(video).on('timeupdate', function (event) {
      const current = this.currentTime
      const percent = this.duration / 100
      const progress = (current / percent).toFixed()
      progressBar.css('width', progress + '%')
    })
  })

  $('.js-audio-block').each((index, item) => {
    const audio = $(item).find('audio')[0]
    const playBtn = $(item).find('.audio-block__controls-play')
    const pauseBtn = $(item).find('.audio-block__controls-pause')
    const progressBar = $(item).find('.audio-block__progress span')
    const time = $(item).find('.audio-block__header-time')
    let audioPlayed = false

    playBtn.on('click', togglePlayer)
    pauseBtn.on('click', togglePlayer)

    function togglePlayer() {
      if (!audioPlayed) {
        audioPlayed = true
        audio.play()
        $(item).addClass('play').removeClass('pause')
      } else {
        audioPlayed = false
        audio.pause()
        $(item).removeClass('play').addClass('pause')
      }
    }

    $(audio).on('timeupdate', function (event) {
      const current = this.currentTime
      const percent = this.duration / 100
      const progress = (current / percent).toFixed()
      time.text(convertTime(current.toFixed()))
      progressBar.css('width', progress + '%')
    })

    function convertTime(sec) {
      var min = Math.floor(sec / 60)
      min >= 1 ? (sec = sec - min * 60) : (min = '0')
      sec < 1 ? (sec = '00') : void 0
      sec.toString().length == 1 ? (sec = '0' + sec) : 0
      return min + ':' + sec
    }
  })

  $('.js-audio-block').draggable()
}
</script>

<style></style>

<template>
  <div class="page cases" id="cases">
    <div class="container">
      <div class="cases__wr">
        <ul class="cases__list">
          <li :data-video="require(`@/assets/video/cases/prevuwe2.mp4`)">
            <router-link to="/cases/taro" :glitch="$t('cases.taro.title')">{{
              $t('cases.taro.title')
            }}</router-link>
            <span>{{ $t('cases.taro.desc') }}</span>
          </li>
          <li
            :data-video="require(`@/assets/video/cases/prevuwe3.mp4`)"
            class="fix-case-size"
          >
            <router-link
              to="/cases/lacoste"
              :glitch="$t('cases.lacoste.title')"
              >{{ $t('cases.lacoste.title') }}</router-link
            >
            <span>{{ $t('cases.lacoste.desc') }}</span>
          </li>
          <li :data-video="require(`@/assets/video/cases/prevuwe4.mp4`)">
            <router-link
              to="/cases/france"
              :glitch="$t('cases.france.title')"
              >{{ $t('cases.france.title') }}</router-link
            >
            <span>{{ $t('cases.france.desc') }}</span>
          </li>
          <li :data-video="require(`@/assets/video/cases/prevuwe5.mp4`)">
            <router-link to="/cases/hugo" :glitch="$t('cases.hugo.title')">{{
              $t('cases.hugo.title')
            }}</router-link>
            <span>{{ $t('cases.hugo.desc') }}</span>
          </li>
          <li :data-video="require(`@/assets/video/cases/prevuwe8.mp4`)">
            <router-link
              to="/cases/elovena"
              :glitch="$t('cases.elovena.title')"
              >{{ $t('cases.elovena.title') }}</router-link
            >
            <span>{{ $t('cases.elovena.desc') }}</span>
          </li>
          <li :data-video="require(`@/assets/video/cases/prevuwe9.mp4`)">
            <router-link to="/cases/boss" :glitch="$t('cases.boss.title')">{{
              $t('cases.boss.title')
            }}</router-link>
            <span>{{ $t('cases.boss.desc') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="cases__images"></div>
    <div class="modal__blue">
      <div class="modal__blue-content">
        <div class="modal__blue-info">
          <p class="modal__blue-label">Technical information:</p>
          <p class="modal__blue-text">
            <span>***</span><span>STOP:</span><span>0x0000001A</span>
          </p>
        </div>
        <div class="modal__blue-code">
          <p class="modal__blue-text">
            52574B4A5C4E144C58575D4E575D144A504E574C62143D4E4C5157524C4A551452574F585B564A5D5258570E1C2A14362E36383B4248362A372A302E362E373D
          </p>
        </div>
        <div class="modal__blue-title">Shit happens</div>
        <button class="modal__blue-button">>press reload page</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    oldCode()
  },
}

const oldCode = () => {
  let start_point = {
    x: Math.random() * (window.innerWidth - 1080),
    y: Math.random() * (window.innerHeight - 640),
  }
  let spawn_interval = null
  let blocks_counter = 0
  let zIndex = 0

  const createCascadeVideo = (video) => {
    const dom = $(`<div class="cases__item">
			<video src="${video}" muted autoplay></video>
		</div>`)

    dom.css('top', start_point.y)
    dom.css('right', start_point.x)
    dom.css('z-index', ++zIndex)

    return dom
  }

  if ($(window).width() >= 992) {
    $('.cases__list li').on('mouseenter', (e) => {
      const li_video = e.currentTarget.dataset.video

      spawn_interval = setInterval(() => {
        if (blocks_counter === 50) return

        $('.cases__images').append(createCascadeVideo(li_video))
        start_point.x += 10
        start_point.y -= 10

        if (
          start_point.x >= window.innerWidth - 1080 ||
          start_point.y <= -100
        ) {
          start_point.x = Math.random() * (window.innerWidth - 1080)
          start_point.y = Math.random() * (window.innerHeight - 640)
        }
        if (++blocks_counter === 40) {
          $('.modal__blue').addClass('modal__blue-active')
        }
      }, 1000)

      $('.cases__list li').on('mouseleave', (e) => {
        clearInterval(spawn_interval)
        spawn_interval = null
      })

      $('.modal__blue-button').on('click', (e) => {
        $('.cases__images').empty()
        blocks_counter = 0
        location.reload()
        $('.modal__blue').removeClass('modal__blue-active')
      })
    })
  }
}
</script>

<style lang="scss">
.cases__item {
  width: 360px;
  height: 640px;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div id="app" class="page-wrap">
    <transition
      :name="name"
      :mode="mode"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
    >
      <router-view class="transition" />
    </transition>
    <div class="offer" :class="{ 'offer--inner': !indexPage, hide: offerHide }">
      <div class="offer__wr">
        <!--<video class="offer__mob-video" autoplay="true" muted="true" loop="true">
					<source src="assets/video/offer.mp4" type="video/mp4">
				</video>-->
        <div class="offer__mob-wr">
          <video
            class="lazy"
            id="main-video"
            autoplay="true"
            muted="true"
            loop="true"
            playsinline
          >
            <source
              :src="require(`@/assets/video/main_mob.mp4`)"
              type="video/mp4"
            />
          </video>
          <img class="offer__logo-mob" src="@/assets/img/bg_mob.png" alt="" />
        </div>
        <svg
          class="offer__logo"
          width="1280"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          style="enable-background: new 0 0 1280 1024"
          xml:space="preserve"
          viewBox="166.85 429.57 947.25 164.19"
        >
          <defs>
            <clipPath id="mob-logo" clipPathUnits="objectBoundingBox">
              <path
                d="M735.2 435.89v12.63h-12.63v12.63H608.9v25.26h50.52v-12.63h12.63v-12.63h50.52v25.26h63.15V524.3h12.63v-88.4l-63.15-.01zm0 75.78v12.63h-12.63v12.63h-25.26V524.3h12.63v-12.63h12.63v-12.63h12.63v12.63zM962.54 454.83V442.2h-12.63v-12.63h-75.78v12.63H861.5v37.89h12.63v12.63h12.63v12.63h12.63v12.63H861.5v12.63h-12.63v-12.63h-12.63v-12.63h-12.63v12.63h12.63v12.63h12.63v12.63h75.78v-12.63h25.26v-25.26h12.63v-37.89h-12.63v-12.63h-12.63v-12.63h-12.63v-12.63h12.63v-12.63h12.63v12.63h12.63v12.63h12.63v-12.63h12.63v-12.63h12.63v-12.63zM621.53 461.15v-12.63H608.9v-12.63H482.6v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h138.93v-12.63h25.26v-12.63h12.63v-12.63h12.63V524.3h-12.63v-12.63h-12.63v-12.63h37.89v-12.63h12.63v-25.26h-12.63zm-75.78 75.78h-12.63v12.63h-37.89v-12.63h12.63V524.3h37.89v12.63zm12.63-50.52v12.63h-37.89v-12.63h12.63v-12.63h37.89v12.63h-12.63zM1013.06 435.89v25.26h-12.63v25.26H987.8v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h113.67v-12.63h12.63v-25.26h-50.52v-12.63h12.63V524.3h37.89v-12.63h12.63v-12.63h-37.89v-12.63h12.63v-12.63h37.89v-12.63h12.63v-25.26zM394.19 435.89v25.26h-12.63v25.26h-12.63v-25.26H356.3v-25.26h-50.52v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h50.52v-25.26h12.63v-25.26h12.63v25.26h12.63v25.26h50.52v-25.26h12.63v-25.26h12.63v-25.26h12.63v-25.26h12.63v-25.26zM255.26 486.41h12.63v-25.26h12.63v-25.26h-63.15v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26H230v-25.26h12.63v-25.26h12.63z"
              />
            </clipPath>
          </defs>
          <g>
            <path
              class="st0"
              d="M735.2,435.89v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63v12.63h-12.63   v12.63h-12.63v12.63h-12.63v12.63H608.9v25.26h50.52v-12.63h12.63v-12.63h50.52v25.26h63.15V524.3h12.63v-88.4L735.2,435.89z    M735.2,511.67v12.63h-12.63v12.63h-25.26V524.3h12.63v-12.63h12.63v-12.63h12.63V511.67z"
            />
            <polygon
              class="st0"
              points="962.54,454.83 962.54,442.2 949.91,442.2 949.91,429.57 874.13,429.57 874.13,442.2 861.5,442.2    861.5,454.83 848.87,454.83 848.87,467.46 836.24,467.46 836.24,505.35 848.87,505.35 848.87,517.98 861.5,517.98 861.5,530.61    874.13,530.61 874.13,543.24 861.5,543.24 861.5,555.87 848.87,555.87 848.87,543.24 836.24,543.24 836.24,530.61 823.61,530.61    823.61,543.24 810.98,543.24 810.98,555.87 798.35,555.87 798.35,568.5 810.98,568.5 810.98,581.13 823.61,581.13 823.61,593.76    899.39,593.76 899.39,581.13 924.65,581.13 924.65,555.87 937.28,555.87 937.28,517.98 924.65,517.98 924.65,505.35 912.02,505.35    912.02,492.72 899.39,492.72 899.39,480.09 912.02,480.09 912.02,467.46 924.65,467.46 924.65,480.09 937.28,480.09 937.28,492.72    949.91,492.72 949.91,480.09 962.54,480.09 962.54,467.46 975.17,467.46 975.17,454.83  "
            />
            <path
              class="st0"
              d="M621.53,461.15v-12.63H608.9v-12.63H482.6v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63v25.26h-12.63   v25.26h138.93v-12.63h25.26v-12.63h12.63v-12.63h12.63V524.3h-12.63v-12.63h-12.63v-12.63h37.89v-12.63h12.63v-25.26H621.53z    M545.75,536.93h-12.63v12.63h-37.89v-12.63h12.63V524.3h12.63h12.63h12.63V536.93z M558.38,486.41v12.63h-12.63h-12.63h-12.63   v-12.63h12.63v-12.63h12.63h12.63h12.63v12.63H558.38z"
            />
            <polygon
              class="st0"
              points="1013.06,435.89 1013.06,461.15 1000.43,461.15 1000.43,486.41 987.8,486.41 987.8,511.67    975.17,511.67 975.17,536.93 962.54,536.93 962.54,562.19 949.91,562.19 949.91,587.45 1063.58,587.45 1063.58,574.82    1076.21,574.82 1076.21,549.56 1025.69,549.56 1025.69,536.93 1038.32,536.93 1038.32,524.3 1076.21,524.3 1076.21,511.67    1088.84,511.67 1088.84,499.04 1050.95,499.04 1050.95,486.41 1063.58,486.41 1063.58,473.78 1101.47,473.78 1101.47,461.15    1114.1,461.15 1114.1,435.89  "
            />
            <g>
              <polygon
                class="st0"
                points="394.19,435.89 394.19,461.15 381.56,461.15 381.56,486.41 368.93,486.41 368.93,461.15 356.3,461.15     356.3,435.89 305.78,435.89 305.78,461.15 293.15,461.15 293.15,486.41 280.52,486.41 280.52,511.67 267.89,511.67 267.89,536.93     255.26,536.93 255.26,562.19 305.78,562.19 305.78,536.93 318.41,536.93 318.41,511.67 331.04,511.67 331.04,536.93     343.67,536.93 343.67,562.19 394.19,562.19 394.19,536.93 406.82,536.93 406.82,511.67 419.45,511.67 419.45,486.41     432.08,486.41 432.08,461.15 444.71,461.15 444.71,435.89   "
              />
              <polygon
                class="st0"
                points="255.26,486.41 267.89,486.41 267.89,461.15 280.52,461.15 280.52,435.89 217.37,435.89     217.37,461.15 204.74,461.15 204.74,486.41 192.11,486.41 192.11,511.67 179.48,511.67 179.48,536.93 166.85,536.93     166.85,562.19 230,562.19 230,536.93 242.63,536.93 242.63,511.67 255.26,511.67   "
              />
            </g>
          </g>
        </svg>
        <nav class="offer__nav">
          <div class="lang-offer-back">
            <router-link
              :glitch="$t('menu.menu')"
              to="/"
              class="offer__back js-offer__back"
              >{{ $t('menu.menu') }}</router-link
            >
          </div>
          <div class="offer__menu-wr">
            <ul class="offer__menu">
              <li class="lang-offer-menu-1 js-lang-offer-menu">
                <router-link :glitch="$t('menu.agency')" to="/agency">{{
                  $t('menu.agency')
                }}</router-link>
              </li>
              <li class="lang-offer-menu-2 js-lang-offer-menu">
                <router-link :glitch="$t('menu.cases')" to="/cases">{{
                  $t('menu.cases')
                }}</router-link>
              </li>
              <li class="lang-offer-menu-3 js-lang-offer-menu">
                <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                  $t('menu.contacts')
                }}</router-link>
              </li>
              <li>
                <a
                  @click.prevent="changeLang"
                  class="lang-btn"
                  href="#"
                  :glitch="$t('menu.lang')"
                  >{{ $t('menu.lang') }}</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <router-link to="/" glitch="меню" class="menu-mobile-link" v-if="offerHide"
      >меню</router-link
    >
    <div class="loader">
      <div class="loader_inner"></div>
    </div>
    <div class="cookies">
      <div class="cookies__text">
        <p class="lang-cookies-text">
          {{ $t('cookies.text') }}
        </p>
      </div>
      <button class="cookies__btn">ok</button>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  data() {
    return {
      name: 'router-view',
      mode: 'in-out',
      indexPage: true,
      init: false,
      count: 0,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    offerHide() {
      const width = $(window).width()
      return this.$route.name !== 'index' && width < 1230
    },
  },
  methods: {
    afterEnter() {
      window.scrollTo(0, 0)
    },
    afterLeave() {},
    changeLang() {
      if (this.$i18n.locale === 'ru') {
        this.$i18n.locale = 'eng'
        localStorage.setItem('locale', 'eng')
      } else {
        this.$i18n.locale = 'ru'
        localStorage.setItem('locale', 'ru')
      }
    },
  },
  beforeMount() {
    const locale = localStorage.getItem('locale')
    if (locale === 'ru' || locale === 'eng') {
      this.$i18n.locale = locale
    }

    if (this.$route.name !== 'index') {
      this.indexPage = false
    }
  },
  mounted() {
    const width = $(window).width()
    const ms = width >= 761 ? 1000 : 2500
    if (!localStorage.cookies) {
      $('.cookies').css('display', 'flex')
      $('.cookies__btn').on('click', function (e) {
        localStorage.cookies = true
        $('.cookies').hide()
      })
    }
    setTimeout(() => {
      anime({
        targets: '.loader',
        opacity: [1, 0],
        duration: 600,
        easing: 'linear',
        autoplay: true,
        complete: function (anim) {
          document.querySelector('.loader').style.display = 'none'
        },
      })
    }, ms)
  },
  watch: {
    routeName(name) {
      const width = $(window).width()
      if (name === 'index') {
        this.name = 'router-view-back'
        this.mode = 'in-out'
        const offerWrAnimation = anime({
          targets: '.offer__wr',
          left: width > 760 ? ['0', '50%'] : ['-100px', '50%'],
          translateX: ['0', '-50%'],
          translateY: ['-50%', '-50%'],
          width: width > 1280 ? '1280px' : width + 'px',
          duration: 600,
          easing: 'linear',
          autoplay: false,
          complete: function (anim) {
            menuAnimation.play()
          },
        })
        const menuAnimation = anime({
          targets: '.offer__menu-wr',
          height: width > 760 ? ['0', '42px'] : ['0', '260px'],
          duration: 200,
          easing: 'linear',
          autoplay: false,
          complete: function (anim) {
            document.querySelector('.offer__menu').style.height = 'auto'
          },
        })
        const backBtnAnimation = anime({
          targets: '.offer__back',
          position: 'static',
          height: '0',
          opacity: 0,
          duration: 200,
          easing: 'linear',
          autoplay: false,
          begin: function (anim) {
            document.querySelector('.offer').style.width = '100%'
            document.querySelector('.offer').classList.remove('offer--inner')
          },
          complete: function (anim) {
            document.querySelector('.offer__back').style.display = 'none'
            offerWrAnimation.play()
          },
        })
        backBtnAnimation.play()
        this.count = 0
      } else {
        if (this.count) return
        this.name = 'router-view'
        this.mode = 'in-out'
        const offerWrAnimation = anime({
          targets: '.offer__wr',
          left: width > 760 ? 0 : '-100px',
          translateX: ['-50%', '0'],
          translateY: ['-50%', '-50%'],
          width: '100px',
          duration: 500,
          easing: 'linear',
          autoplay: false,
          complete: function (anim) {
            backBtnAnimation.play()
          },
        })
        const menuAnimation = anime({
          targets: '.offer__menu-wr',
          height: width > 760 ? ['42px', '0'] : ['260px', '0'],
          duration: 200,
          easing: 'linear',
          autoplay: false,
        })
        const backBtnAnimation = anime({
          targets: '.offer__back',
          position: 'static',
          height: '26px',
          opacity: 1,
          duration: 400,
          easing: 'linear',
          autoplay: false,
          begin: function (anim) {
            document.querySelector('.offer__back').style.display = 'block'
          },
          complete: function (anim) {
            document.querySelector('.offer').style.width = '100px'
          },
        })
        menuAnimation.play()
        offerWrAnimation.play()
        this.count++
      }
    },
  },
}
</script>

<style lang="scss">
$duration: 1s;
.transition {
  overflow: hidden;
}
.router-view,
.router-view-back {
  &-enter-active,
  &-leave-active {
    position: fixed;
    width: 100%;
    background: white;
    min-height: 100vh;
    top: 0;
  }
}
// router view
.router-view-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(100%);
}
.router-view-enter-to {
  z-index: 2;
  transform: translateX(0%);
}
.router-view-leave-active {
  z-index: -1;
}
.router-view-leave-to {
  z-index: -1;
}

// router view back
.router-view-back-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(-100%);
  background-color: #151819;
}
.router-view-back-enter-to {
  z-index: 2;
  transform: translateX(0%);
}
.router-view-back-leave-active {
  z-index: -1;
}
.router-view-back-leave-to {
  z-index: -1;
}
</style>

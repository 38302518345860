<template>
  <div class="animated-bg">
    <header class="cases-header">
      <div class="container">
        <div class="cases-header__wr">
          <div class="cases-header__col-left">
            <h1 class="cases-header__title">
              <span v-html="$t('elovena.title')"></span>
              <i>{{ $t('elovena.desc') }}</i>
            </h1>
            <ul class="cases-header__marks">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="180"
                  height="52"
                  viewBox="0 0 180 52"
                >
                  <path
                    id="creative_copy"
                    data-name="creative copy"
                    d="M3113.55,261.123h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v2.7h-2.7v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.7v2.7h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm26.66,0h-1.36v-1.35h-1.35v-1.35h-1.35v-1.349h-1.34v-1.35h-1.35v8.1h-2.7v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.36v1.349h1.34v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.34v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.34v-2.7h-1.34v-1.349Zm9.11-2.7h12.14v2.7h-9.44v5.4h6.74v2.7h-6.74v5.4h9.44v2.7h-12.14v-18.9Zm29.34,10.8h1.35v8.1h-2.7v-4.049h-8.1v4.049h-2.7v-8.1h1.35v-2.7h1.35v-2.7h1.35v-2.7h1.35v-2.7h2.7v2.7h1.35v2.7h1.35v2.7h1.35v2.7Zm-8.1,1.35h5.4v-2.7h-1.35v-4.05h-2.7v4.05h-1.35v2.7Zm25.27-12.148v2.7h-5.41v16.2h-2.69v-16.2h-5.4v-2.7h13.5Zm7.08,2.7h-2.7v-2.7h8.1v2.7h-2.7v13.5h2.7v2.7h-8.1v-2.7h2.7v-13.5Zm11.79,2.7h-1.35v-5.4h2.7v4.049h1.34v4.05h1.36v4.049h2.7v-4.049h1.34v-4.05h1.35v-4.049h2.7v5.4h-1.35v4.05h-1.35v4.049h-1.35v2.7h-1.34v2.7h-2.7v-2.7h-1.36v-2.7h-1.34v-4.049h-1.35v-4.05Zm17.21-5.4h12.14v2.7h-9.45v5.4h6.75v2.7h-6.75v5.4h9.45v2.7h-12.14v-18.9ZM3113,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm148-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16,16H3113v-4h132v4Zm0-48H3113v-4h132v4Z"
                    transform="translate(-3089 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="220"
                  height="52"
                  viewBox="0 0 220 52"
                >
                  <path
                    id="production_copy"
                    data-name="production copy"
                    d="M3336.34,253.025v1.349h-1.35v1.35h-8.1v8.1h-2.69v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35Zm-2.7-5.4h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm18.43,13.5h-1.35v-1.35h-1.35v-1.35h-1.35v-1.349h-1.35v-1.35h-1.35v8.1h-2.7v-18.9h10.8v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.35v1.349h1.35v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.35v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm10.33,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v13.5h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-10.8h-1.34v-1.349Zm21.15,13.5v1.349h-1.34v1.35h-10.8v-18.9h10.8v1.35h1.34v1.35h1.36v13.5h-1.36Zm-2.69-13.5h-6.75v13.5h6.75v-1.35h1.35v-10.8h-1.35v-1.349Zm10.34,13.5h-1.34v-16.2h2.69v14.847H3402v1.35h5.4v-1.35h1.35V244.926h2.7v16.2h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm18.45,0h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.35v2.7h-2.71v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.71v2.7h-1.35v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm29.92-16.2v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm6.95,2.7h-2.69v-2.7h8.09v2.7h-2.7v13.5h2.7v2.7h-8.09v-2.7h2.69v-13.5Zm11.65,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.1v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.35v-10.8h-1.35v-1.349Zm11.71,16.2h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.349h1.35v2.7h1.35v1.349h1.35v2.7h1.35V244.926h2.7v18.9h-2.7v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v12.148ZM3325,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm188-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3325v-4h172v4Zm0,48H3325v-4h172v4Z"
                    transform="translate(-3301 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="26"
                  viewBox="0 0 48 26"
                >
                  <path
                    id="smm"
                    d="M292.323,339.813h-2.7v0.675h-0.675v1.349h0.675v0.675H293v0.675h0.675v0.675h0.675v2.7h-0.675v0.675H293v0.675h-4.049v-0.675h-0.675v-0.675H287.6v-1.35h1.349v0.675h0.675v0.675h2.7v-0.675H293v-1.349h-0.674v-0.675h-3.375v-0.675h-0.675v-0.675H287.6v-2.7h0.674v-0.675h0.675v-0.675H293v0.675h0.675v0.675h0.675v1.349H293v-0.674h-0.674v-0.675Zm5.972,2.7h-0.674v5.4h-1.35v-9.448h1.35v1.35H298.3v1.349h0.675v1.35h1.35v-1.35H301v-1.349h0.675v-1.35h1.349v9.448H301.67v-5.4H301v1.35H300.32v1.349h-1.35v-1.349H298.3v-1.35Zm8.717,0h-0.674v5.4h-1.35v-9.448h1.35v1.35h0.674v1.349h0.675v1.35h1.349v-1.35h0.676v-1.349h0.674v-1.35h1.35v9.448h-1.35v-5.4h-0.674v1.35h-0.676v1.349h-1.349v-1.349h-0.675v-1.35ZM288,332h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V338h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm32-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V338h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16H288v-2h24v2Zm0,24H288v-2h24v2Z"
                    transform="translate(-276 -330)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="26"
                  viewBox="0 0 102 26"
                >
                  <path
                    id="community"
                    class="cls-1"
                    d="M1896.27,306.561h-0.67v-6.748h0.67v-0.675h0.68v-0.674H1901v0.674h0.67v0.675h0.68v1.35H1901v-0.675h-0.68v-0.675h-2.7v0.675h-0.67v5.4h0.67v0.674h2.7v-0.674H1901v-0.675h1.35v1.349h-0.68v0.676H1901v0.674h-4.05v-0.674h-0.68v-0.676Zm9.38,0h-0.68v-6.748h0.68v-0.675h0.67v-0.674h4.05v0.674h0.68v0.675h0.67v6.748h-0.67v0.676h-0.68v0.674h-4.05v-0.674h-0.67v-0.676Zm4.05-6.748H1907v0.675h-0.68v5.4H1907v0.674h2.7v-0.674h0.67v-5.4h-0.67v-0.675Zm6.69,2.7h-0.67v5.4h-1.35v-9.447h1.35v1.349h0.67v1.35h0.68v1.349h1.34v-1.349h0.68v-1.35h0.67v-1.349h1.35v9.447h-1.35v-5.4h-0.67v1.35h-0.68v1.35h-1.34v-1.35h-0.68v-1.35Zm9.42,0h-0.68v5.4h-1.34v-9.447h1.34v1.349h0.68v1.35h0.67v1.349h1.35v-1.349h0.68v-1.35h0.67v-1.349h1.35v9.447h-1.35v-5.4h-0.67v1.35h-0.68v1.35h-1.35v-1.35h-0.67v-1.35Zm8.06,4.049h-0.67v-8.1h1.35v7.423h0.67v0.674h2.7v-0.674h0.68v-7.423h1.35v8.1h-0.68v0.676h-0.67v0.674h-4.05v-0.674h-0.68v-0.676Zm10.09,1.35h-1.35v-9.447h1.35v0.674h0.67v1.35h0.68v0.675h0.67v1.349h0.68v0.675h0.67v1.35h0.68v-6.073h1.35v9.447h-1.35v-0.674h-0.68v-1.35h-0.67v-0.675h-0.68v-1.35h-0.67v-0.675h-0.68v-1.35h-0.67v6.074Zm9.37-8.1h-1.35v-1.349h4.05v1.349h-1.35v6.748h1.35v1.35h-4.05v-1.35h1.35v-6.748Zm11.73-1.349v1.349h-2.7v8.1h-1.35v-8.1h-2.69v-1.349h6.74Zm2.97,2.024h-0.68v-2.024h1.35v1.349h0.68v1.35h0.67v1.349h1.35v-1.349h0.68v-1.35h0.67v-1.349h1.35v2.024h-0.67v1.349h-0.68v1.35h-0.67v1.35h-0.68v3.374h-1.35v-3.374h-0.67v-1.35h-0.68v-1.35h-0.67v-1.349ZM1896,292h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V298h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm86-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V298h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16h-78v-2h78v2Zm0,24h-78v-2h78v2Z"
                    transform="translate(-1884 -290)"
                  />
                </svg>
              </li>
            </ul>
            <ul class="cases-header__nums">
              <li>
                <b>13</b>
                <span>{{ $t('other.million') }}</span>
                <p>{{ $t('other.coverage') }}</p>
              </li>
              <li>
                <b>8,3</b>
                <span>%</span>
                <p>ER</p>
              </li>
            </ul>
          </div>
          <div class="cases-header__col-right">
            <div class="cases-header__desc" v-html="$t('elovena.text_1')"></div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section class="elovena-questions" id="app">
        <div class="container">
          <h1 class="elovena-questions__title">gluten-free test</h1>
          <div
            class="elovena-questions__item"
            v-for="question in questions"
            :key="question.id"
            v-show="currentQuestion >= question.id"
          >
            <p
              class="elovena-questions__item-question"
              v-html="question.question"
            ></p>
            <div class="elovena-questions__item-answers">
              <span
                v-for="(answer, index) in question.answers"
                @click="clickAnswer(question.id, index)"
                :class="{ active: question.answered === index }"
              >
                {{ answer.name }}
              </span>
            </div>
            <img
              v-if="question.answered !== false"
              class="elovena-questions__item-image"
              :src="question.answers[question.answered].image"
              alt=""
            />
            <p
              v-if="question.answered !== false"
              class="elovena-questions__item-answer"
              v-html="question.answer"
            ></p>
          </div>
        </div>
      </section>
      <section class="elovena-social">
        <div class="container">
          <div class="elovena-social__wr">
            <h2>{{ $t('elovena.socialTitle') }}</h2>
            <p v-html="$t('elovena.socialText')"></p>
          </div>
        </div>
      </section>
    </main>
    <footer class="cases-footer">
      <div class="container">
        <div class="cases-footer__wr">
          <ul class="cases-footer__menu">
            <li>
              <router-link :glitch="$t('menu.agency')" to="/agency">{{
                $t('menu.agency')
              }}</router-link>
            </li>
            <li class="lang-offer-menu-2 js-lang-offer-menu">
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
            </li>
            <li>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </li>
          </ul>
          <img src="@/assets/img/agency/mouse.gif" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentQuestion: 1,
      questions: {
        1: {
          id: 1,
          question: this.$t('elovena.question_1_title'),
          answer: this.$t('elovena.question_1_answer'),
          answers: [
            {
              name: this.$t('elovena.question_1_answers[0]'),
              image: require('@/assets/img/elovena/question_1/gluten.jpg'),
            },
            {
              name: this.$t('elovena.question_1_answers[1]'),
              image: require('@/assets/img/elovena/question_1/sladkoe.jpg'),
            },
            {
              name: this.$t('elovena.question_1_answers[2]'),
              image: require('@/assets/img/elovena/question_1/utro.jpg'),
            },
          ],
          answered: false,
        },
        2: {
          id: 2,
          question: this.$t('elovena.question_2_title'),
          answer: this.$t('elovena.question_2_answer'),
          answers: [
            {
              name: this.$t('elovena.question_2_answers[0]'),
              image: require('@/assets/img/elovena/question_2/muka.jpg'),
            },
            {
              name: this.$t('elovena.question_2_answers[1]'),
              image: require('@/assets/img/elovena/question_2/kasha.jpg'),
            },
            {
              name: this.$t('elovena.question_2_answers[2]'),
              image: require('@/assets/img/elovena/question_2/galeta.jpg'),
            },
          ],
          answered: false,
        },
        3: {
          id: 3,
          question: this.$t('elovena.question_3_title'),
          answer: this.$t('elovena.question_3_answer'),
          answers: [
            {
              name: this.$t('elovena.question_3_answers[0]'),
              image: require('@/assets/img/elovena/question_3/pechenie.jpg'),
            },
            {
              name: this.$t('elovena.question_3_answers[1]'),
              image: require('@/assets/img/elovena/question_3/hleb.jpg'),
            },
            {
              name: this.$t('elovena.question_3_answers[2]'),
              image: require('@/assets/img/elovena/question_3/bliny.jpg'),
            },
          ],
          answered: false,
        },
        4: {
          id: 4,
          question: this.$t('elovena.question_4_title'),
          answer: this.$t('elovena.question_4_answer'),
          answers: [
            {
              name: this.$t('elovena.question_4_answers[0]'),
              image: require('@/assets/img/elovena/question_4/hlopiya.jpg'),
            },
            {
              name: this.$t('elovena.question_4_answers[1]'),
              image: require('@/assets/img/elovena/question_4/kasha_po_bystromu.jpg'),
            },
            {
              name: this.$t('elovena.question_4_answers[2]'),
              image: require('@/assets/img/elovena/question_4/limon.jpg'),
            },
          ],
          answered: false,
        },
        5: {
          id: 5,
          question: this.$t('elovena.question_5_title'),
          answer: this.$t('elovena.question_5_answer'),
          answers: [
            {
              name: this.$t('elovena.question_5_answers[0]'),
              image: require('@/assets/img/elovena/question_5/ispeku.jpg'),
            },
            {
              name: this.$t('elovena.question_5_answers[1]'),
              image: require('@/assets/img/elovena/question_5/pechenie_s_polki.jpg'),
            },
            {
              name: this.$t('elovena.question_5_answers[2]'),
              image: require('@/assets/img/elovena/question_5/knigu.jpg'),
            },
          ],
          answered: false,
        },
      },
    }
  },
  methods: {
    clickAnswer(id, i) {
      this.questions[id].answered = i
      if (this.currentQuestion === id) {
        this.currentQuestion++
      }
    },
  },
  mounted() {
    oldCode()
  },
}

const oldCode = () => {
  const bgController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '.animated-bg',
    duration: $('.animated-bg').height(),
    offset: 800,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(bgController)
}
</script>

<style></style>

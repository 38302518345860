<template>
  <div class="animated-bg">
    <link rel="preload" as="image" href="/img/taro/card_10.jpg" />
    <link rel="preload" as="image" href="/img/taro/card_3.jpg" />
    <link rel="preload" as="image" href="/img/taro/card_11.jpg" />
    <link rel="preload" as="image" href="/img/taro/card_17.jpg" />
    <link rel="preload" as="image" href="/img/taro/card_10.jpg" />
    <header class="cases-header">
      <div class="container">
        <div class="cases-header__wr">
          <div class="cases-header__col-left">
            <h1 class="cases-header__title">
              <span>{{ $t('taro.title') }}</span>
              <i>{{ $t('taro.desc') }}</i>
            </h1>
            <ul class="cases-header__marks">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="180"
                  height="52"
                  viewBox="0 0 180 52"
                >
                  <path
                    id="creative_copy"
                    data-name="creative copy"
                    d="M3113.55,261.123h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v2.7h-2.7v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.7v2.7h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm26.66,0h-1.36v-1.35h-1.35v-1.35h-1.35v-1.349h-1.34v-1.35h-1.35v8.1h-2.7v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.36v1.349h1.34v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.34v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.34v-2.7h-1.34v-1.349Zm9.11-2.7h12.14v2.7h-9.44v5.4h6.74v2.7h-6.74v5.4h9.44v2.7h-12.14v-18.9Zm29.34,10.8h1.35v8.1h-2.7v-4.049h-8.1v4.049h-2.7v-8.1h1.35v-2.7h1.35v-2.7h1.35v-2.7h1.35v-2.7h2.7v2.7h1.35v2.7h1.35v2.7h1.35v2.7Zm-8.1,1.35h5.4v-2.7h-1.35v-4.05h-2.7v4.05h-1.35v2.7Zm25.27-12.148v2.7h-5.41v16.2h-2.69v-16.2h-5.4v-2.7h13.5Zm7.08,2.7h-2.7v-2.7h8.1v2.7h-2.7v13.5h2.7v2.7h-8.1v-2.7h2.7v-13.5Zm11.79,2.7h-1.35v-5.4h2.7v4.049h1.34v4.05h1.36v4.049h2.7v-4.049h1.34v-4.05h1.35v-4.049h2.7v5.4h-1.35v4.05h-1.35v4.049h-1.35v2.7h-1.34v2.7h-2.7v-2.7h-1.36v-2.7h-1.34v-4.049h-1.35v-4.05Zm17.21-5.4h12.14v2.7h-9.45v5.4h6.75v2.7h-6.75v5.4h9.45v2.7h-12.14v-18.9ZM3113,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm148-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16,16H3113v-4h132v4Zm0-48H3113v-4h132v4Z"
                    transform="translate(-3089 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="220"
                  height="52"
                  viewBox="0 0 220 52"
                >
                  <path
                    id="production_copy"
                    data-name="production copy"
                    d="M3336.34,253.025v1.349h-1.35v1.35h-8.1v8.1h-2.69v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35Zm-2.7-5.4h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm18.43,13.5h-1.35v-1.35h-1.35v-1.35h-1.35v-1.349h-1.35v-1.35h-1.35v8.1h-2.7v-18.9h10.8v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.35v1.349h1.35v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.35v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm10.33,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v13.5h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-10.8h-1.34v-1.349Zm21.15,13.5v1.349h-1.34v1.35h-10.8v-18.9h10.8v1.35h1.34v1.35h1.36v13.5h-1.36Zm-2.69-13.5h-6.75v13.5h6.75v-1.35h1.35v-10.8h-1.35v-1.349Zm10.34,13.5h-1.34v-16.2h2.69v14.847H3402v1.35h5.4v-1.35h1.35V244.926h2.7v16.2h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm18.45,0h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.35v2.7h-2.71v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.71v2.7h-1.35v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm29.92-16.2v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm6.95,2.7h-2.69v-2.7h8.09v2.7h-2.7v13.5h2.7v2.7h-8.09v-2.7h2.69v-13.5Zm11.65,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.1v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.35v-10.8h-1.35v-1.349Zm11.71,16.2h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.349h1.35v2.7h1.35v1.349h1.35v2.7h1.35V244.926h2.7v18.9h-2.7v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v12.148ZM3325,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm188-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3325v-4h172v4Zm0,48H3325v-4h172v4Z"
                    transform="translate(-3301 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="204"
                  height="52"
                  viewBox="0 0 204 52"
                >
                  <path
                    id="community_copy"
                    data-name="community copy"
                    d="M3113.55,341.123h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v2.7h-2.7v-1.35h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.351h5.4v-1.351h1.34v-1.349h2.7v2.7h-1.34v1.349h-1.36v1.349h-8.09v-1.349h-1.35v-1.349Zm18.75,0h-1.36v-13.5h1.36v-1.35h1.35v-1.35h8.09v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.349h-8.09v-1.349h-1.35v-1.349Zm8.09-13.5H3135v1.349h-1.35v10.8H3135v1.351h5.39v-1.351h1.35v-10.8h-1.35v-1.349Zm13.39,5.4h-1.35v10.8h-2.7v-18.9h2.7v2.7h1.35v2.7h1.35v2.7h2.7v-2.7h1.35v-2.7h1.35v-2.7h2.7v18.9h-2.7v-10.8h-1.35v2.7h-1.35v2.7h-2.7v-2.7h-1.35v-2.7Zm18.84,0h-1.35v10.8h-2.7v-18.9h2.7v2.7h1.35v2.7h1.35v2.7h2.7v-2.7h1.35v-2.7h1.35v-2.7h2.7v18.9h-2.7v-10.8h-1.35v2.7h-1.35v2.7h-2.7v-2.7h-1.35v-2.7Zm16.12,8.1h-1.34v-16.2h2.7v14.846h1.34v1.351h5.4v-1.351h1.35V324.926h2.7v16.2h-1.35v1.349h-1.35v1.349h-8.09v-1.349h-1.36v-1.349Zm20.18,2.7h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.35h1.35v2.7h1.35v1.348h1.35v2.7h1.35V324.926h2.7v18.9h-2.7v-1.349h-1.35v-2.7h-1.35v-1.349h-1.35v-2.7h-1.35v-1.349h-1.35v-2.7h-1.35v12.146Zm18.75-16.2h-2.7v-2.7h8.1v2.7h-2.71v13.5h2.71v2.7h-8.1v-2.7h2.7v-13.5Zm23.46-2.7v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm5.93,4.049h-1.35v-4.049h2.7v2.7h1.34v2.7h1.36v2.7h2.69v-2.7h1.35v-2.7h1.35v-2.7h2.7v4.049h-1.34v2.7h-1.36v2.7h-1.35v2.7h-1.35v6.749h-2.69v-6.749h-1.36v-2.7h-1.34v-2.7h-1.35v-2.7ZM3113,312h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V324h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm172-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V324h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3113v-4h156v4Zm0,48H3113v-4h156v4Z"
                    transform="translate(-3089 -308)"
                  />
                </svg>
              </li>
            </ul>
            <ul class="cases-header__nums">
              <li>
                <b>150</b>
                <span></span>
                <p>{{ $t('other.publications') }}</p>
              </li>
              <li>
                <b>2</b>
                <span>{{ $t('other.million') }}</span>
                <p>{{ $t('other.coverage') }}</p>
              </li>
            </ul>
          </div>
          <div class="cases-header__col-right">
            <div class="cases-header__desc" v-html="$t('taro.text_1')"></div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section class="taro-cards">
        <div class="container">
          <div class="taro-cards__wr">
            <div class="taro-cards__deck">
              <img
                src="@/assets/img/taro/deck.png"
                alt=""
                style="opacity: 0; width: 100%"
              />
            </div>
            <div class="taro-cards__main">
              <img src="@/assets/img/taro/deck.png" alt="" />
              <div class="taro-cards__card">
                <div class="taro-cards__card-bg"></div>
                <div class="taro-cards__card-bg-fix">
                  <img src="@/assets/img/taro/card_1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="taro-cards__text">
              <div class="taro-cards__text-item" style="display: none">
                <h2><span>Император</span></h2>
                <p>
                  Карта перфекциониста, который вечно стремится к тому, чтобы
                  все улучшать до бесконечности. Пока другие пытаются положиться
                  на вас, положитесь на широкую палитру лаков. С ней вы точно
                  подберете подходящий оттенок маникюра к вашему образу. Однако
                  не переусердствуйте с красками, не позволяйте эмоциям
                  резонировать. Сохраняйте ум трезвым и продолжайте бить в одну
                  точку, пока не добьетесь своего.
                </p>
                <button
                  class="taro-cards__btn js-card-new-btn"
                  :glitch="$t('taro.clickMore')"
                >
                  {{ $t('taro.clickMore') }}
                </button>
              </div>
              <button
                class="taro-cards__btn taro-cards__btn--main js-card-btn"
                :glitch="$t('taro.click')"
              >
                {{ $t('taro.click') }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="moon">
        <div class="container">
          <div class="moon__wr">
            <img src="@/assets/img/taro/moon/0.png" alt="" />
          </div>
        </div>
      </section>
      <section class="cards-grid">
        <div class="container">
          <div class="cards-grid__text">
            <h2 v-html="$t('taro.subTitle')"></h2>
            <p v-html="$t('taro.subDesc')"></p>
          </div>
          <div class="cards-grid__grid">
            <div><img src="@/assets/img/taro/card_5.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_1.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_22.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_3.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_4.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_21.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_2.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_11.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_16.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_18.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_20.jpg" alt="" /></div>
            <div><img src="@/assets/img/taro/card_14.jpg" alt="" /></div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_15.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_19.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_12.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_13.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_9.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_7.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_17.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_6.jpg" alt="" />
            </div>
            <span class="d-none-lg"></span>
            <div class="d-none">
              <img src="@/assets/img/taro/card_8.jpg" alt="" />
            </div>
            <div class="d-none">
              <img src="@/assets/img/taro/card_10.jpg" alt="" />
            </div>
          </div>
          <button class="cards-grid__show-more">
            {{ $t('taro.clickMoreCards') }}
          </button>
        </div>
      </section>
      <section class="cards-public">
        <div class="container">
          <div class="cards-public__wr">
            <div class="cards-public__text">
              <h2>{{ $t('taro.contentTitle') }}</h2>
              <p v-html="$t('taro.contentDesc')"></p>
            </div>
            <div class="cards-public__cards">
              <div class="cards-public__cards-wr">
                <div class="cards-public__cards-item">
                  <img
                    src="@/assets/img/taro/public/1.png"
                    width="176"
                    height="312"
                    alt=""
                  />
                  <span>{{ $t('taro.unboxing') }}</span>
                </div>
                <div class="cards-public__cards-item">
                  <img
                    src="@/assets/img/taro/public/2.png"
                    width="176"
                    height="312"
                    alt=""
                  />
                  <span>{{ $t('taro.divination') }}</span>
                </div>
                <div class="cards-public__cards-item">
                  <img
                    src="@/assets/img/taro/public/3.png"
                    width="176"
                    height="312"
                    alt=""
                  />
                  <span v-html="$t('taro.makeup')"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="cases-footer">
      <div class="container">
        <div class="cases-footer__wr">
          <ul class="cases-footer__menu">
            <li>
              <router-link :glitch="$t('menu.agency')" to="/agency">{{
                $t('menu.agency')
              }}</router-link>
            </li>
            <li class="lang-offer-menu-2 js-lang-offer-menu">
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
            </li>
            <li>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </li>
          </ul>
          <img src="@/assets/img/agency/mouse.gif" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  mounted() {
    oldCode(this)
  },
}

const oldCode = (self) => {
  const cardWidth = document.querySelector('.taro-cards__card').clientWidth
  document.documentElement.style.setProperty('--card-width', `${cardWidth}px`)

  const bgController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '.animated-bg',
    duration: $('.animated-bg').height(),
    offset: 800,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(bgController)

  let cardsArr = [
    {
      id: 7,
      title: self.$t('taro.cards.emperor.title'),
      text: self.$t('taro.cards.emperor.text'),
    },
    {
      id: 3,
      title: self.$t('taro.cards.devil.title'),
      text: self.$t('taro.cards.devil.text'),
    },
    {
      id: 11,
      title: self.$t('taro.cards.moon.title'),
      text: self.$t('taro.cards.moon.text'),
    },
    {
      id: 17,
      title: self.$t('taro.cards.death.title'),
      text: self.$t('taro.cards.death.text'),
    },
    {
      id: 10,
      title: self.$t('taro.cards.fortune.title'),
      text: self.$t('taro.cards.fortune.text'),
    },
  ]

  const card = $('.taro-cards__card img')
  let cardId = null

  const getRandomCard = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)].id
  }

  const openCardHandler = () => {
    const id = getRandomCard(cardsArr)
    const currentCard = cardsArr.filter((elem) => elem.id == id)[0]
    console.log(card.attr('src'))
    card.attr('src', `/img/taro/card_${id}.jpg`)
    $('.taro-cards__main').addClass('opened')
    $('.taro-cards__text-item h2 span')
      .text(currentCard.title)
      .addClass('easy-top')
    $('.taro-cards__text-item p').html(currentCard.text).addClass('fade-in')
    $('.taro-cards__main').addClass('active')
    $('.taro-cards__text-item').show()
    $('.js-card-btn').hide()
    if (cardsArr.length > 1) {
      $('.js-card-new-btn').addClass('fade-in')
      cardsArr = cardsArr.filter((item) => item.id !== id)
    } else {
      $('.js-card-new-btn').hide()
    }
  }

  const newCardHandler = () => {
    $('.taro-cards__main').removeClass('active')
    $('.taro-cards__text-item').fadeOut(600)
    setTimeout(() => {
      openCardHandler()
    }, 1000)
  }
  $('.js-card-btn, .taro-cards__main').on('click', openCardHandler)
  $('.js-card-new-btn').on('click', newCardHandler)

  if ($(window).width() <= 991) {
    $('.d-none-lg').remove()
    $('.cards-grid__show-more').on('click', function () {
      $(this).remove()
      $('.cards-grid__grid .d-none').removeClass('d-none')
    })
  } else {
    $('.cards-grid__show-more').remove()
    $('.cards-grid__grid .d-none').removeClass('d-none')
  }

  // if ($(document).scrollTop() > $('.cards-grid').offset().top) {
  // 	$('body').css({ background: 'rgb(0, 0, 0)' });
  // }

  const cardsController = new ScrollMagic.Controller()

  const cardsBgAnimation = new ScrollMagic.Scene({
    triggerElement: '.moon',
    duration: 1500,
    reverse: true,
  })
    .setTween('.animated-bg', {
      background: '#0000000',
    })
    .addTo(cardsController)

  const moonAnimation = new ScrollMagic.Scene({
    triggerElement: '.moon',
    offset: -250,
    duration: 1250,
    reverse: true,
  })
    .on('progress', function (event) {
      let count = event.progress.toFixed(1) * 10
      if (count > 9) count = 9
      $('.moon__wr img').attr('src', `/img/taro/moon/${count}.png`)
    })
    .addTo(cardsController)

  const firstCardPublicAnimation = new ScrollMagic.Scene({
    triggerElement: '.cards-public',
    offset: 250,
    reverse: true,
  })
    .setClassToggle('.cards-public__cards-item:first-child', 'active')
    .addTo(cardsController)

  const secondCardPublicAnimation = new ScrollMagic.Scene({
    triggerElement: '.cards-public',
    offset: 250,
    reverse: true,
  })
    .setClassToggle('.cards-public__cards-item:last-child', 'active')
    .addTo(cardsController)
}
</script>

<style></style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import messages from './lang.js'
Vue.use(VueI18n)

import * as ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from 'gsap'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $
window.ScrollMagic = ScrollMagic

import 'normalize.css'
import '@/assets/sass/main.scss'
import '@/assets/css/custom.css'
import '@/assets/css/style.css'

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'ru',
  messages,
})

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app')

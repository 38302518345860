<template>
  <div class="page contacts" id="contacts">
    <div class="container">
      <div class="contacts__wr lang-contacts">
        <h2 class="contacts__city">{{ $t('contacts.city') }}</h2>
        <p class="contacts__address">{{ $t('contacts.address') }}</p>
        <a href="tel:+74993912484" class="contacts__phone">+7 499 391-24-84</a>
        <div class="contacts__links">
          <!--<a href="#" target="_blank">whatsupp </a>-->
          <a
            href="mailto:hello@inbase.me"
            glitch="hello@inbase.me"
            target="_blank"
            >hello@inbase.me</a
          >
          <a href="https://vk.com/inbase_agency" glitch="VK" target="_blank"
            >VK</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>

<template>
  <div class="">
    <div class="page-index">
      <div class="video">
        <video
          class="lazy"
          id="main-video"
          autoplay="true"
          muted="true"
          loop="true"
          playsinline
        >
          <source
            :src="require(`@/assets/video/${video}.mp4`)"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: '01_01',
    }
  },
  beforeMount() {
    const main_videos = ['01_01', '02_01', '03_01', '04_01']
    this.video = main_videos[Math.floor(Math.random() * 4)]
  },
}
</script>

<style lang="scss" scoped>
.page-index {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  video {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    @media (max-width: 760px) {
      display: none !important;
    }
  }
}
a {
  position: relative;
  z-index: 55;
  top: -50px;
}
</style>

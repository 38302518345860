<template>
  <div class="animated-bg">
    <header class="cases-header">
      <div class="container">
        <div class="cases-header__wr">
          <div class="cases-header__col-left">
            <h1 class="cases-header__title">
              <span v-html="$t('boss.title')"></span>
              <i>{{ $t('boss.desc') }}</i>
            </h1>
            <ul class="cases-header__marks">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="180"
                  height="52"
                  viewBox="0 0 180 52"
                >
                  <path
                    id="creative_copy"
                    data-name="creative copy"
                    d="M3113.55,261.123h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v2.7h-2.7v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.7v2.7h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm26.66,0h-1.36v-1.35h-1.35v-1.35h-1.35v-1.349h-1.34v-1.35h-1.35v8.1h-2.7v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.36v1.349h1.34v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.34v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.34v-2.7h-1.34v-1.349Zm9.11-2.7h12.14v2.7h-9.44v5.4h6.74v2.7h-6.74v5.4h9.44v2.7h-12.14v-18.9Zm29.34,10.8h1.35v8.1h-2.7v-4.049h-8.1v4.049h-2.7v-8.1h1.35v-2.7h1.35v-2.7h1.35v-2.7h1.35v-2.7h2.7v2.7h1.35v2.7h1.35v2.7h1.35v2.7Zm-8.1,1.35h5.4v-2.7h-1.35v-4.05h-2.7v4.05h-1.35v2.7Zm25.27-12.148v2.7h-5.41v16.2h-2.69v-16.2h-5.4v-2.7h13.5Zm7.08,2.7h-2.7v-2.7h8.1v2.7h-2.7v13.5h2.7v2.7h-8.1v-2.7h2.7v-13.5Zm11.79,2.7h-1.35v-5.4h2.7v4.049h1.34v4.05h1.36v4.049h2.7v-4.049h1.34v-4.05h1.35v-4.049h2.7v5.4h-1.35v4.05h-1.35v4.049h-1.35v2.7h-1.34v2.7h-2.7v-2.7h-1.36v-2.7h-1.34v-4.049h-1.35v-4.05Zm17.21-5.4h12.14v2.7h-9.45v5.4h6.75v2.7h-6.75v5.4h9.45v2.7h-12.14v-18.9ZM3113,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm148-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16,16H3113v-4h132v4Zm0-48H3113v-4h132v4Z"
                    transform="translate(-3089 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="220"
                  height="52"
                  viewBox="0 0 220 52"
                >
                  <path
                    id="production_copy"
                    data-name="production copy"
                    d="M3336.34,253.025v1.349h-1.35v1.35h-8.1v8.1h-2.69v-18.9h10.79v1.35h1.35v1.35h1.35v5.4h-1.35Zm-2.7-5.4h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm18.43,13.5h-1.35v-1.35h-1.35v-1.35h-1.35v-1.349h-1.35v-1.35h-1.35v8.1h-2.7v-18.9h10.8v1.35h1.35v1.35h1.35v5.4h-1.35v1.349h-1.35v1.35h-2.7v1.35h1.35v1.349h1.35v1.35h1.35v1.35h1.35v2.7h-2.7v-1.35h-1.35v-1.349Zm0-13.5h-6.75v5.4h6.75v-1.351h1.35v-2.7h-1.35v-1.349Zm10.33,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.34v13.5h-1.34v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-10.8h-1.34v-1.349Zm21.15,13.5v1.349h-1.34v1.35h-10.8v-18.9h10.8v1.35h1.34v1.35h1.36v13.5h-1.36Zm-2.69-13.5h-6.75v13.5h6.75v-1.35h1.35v-10.8h-1.35v-1.349Zm10.34,13.5h-1.34v-16.2h2.69v14.847H3402v1.35h5.4v-1.35h1.35V244.926h2.7v16.2h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm18.45,0h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.09v1.35h1.36v1.35h1.35v2.7h-2.71v-1.349h-1.34v-1.349h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.34v-1.35h2.71v2.7h-1.35v1.349h-1.36v1.35h-8.09v-1.35h-1.35v-1.349Zm29.92-16.2v2.7h-5.4v16.2h-2.7v-16.2h-5.4v-2.7h13.5Zm6.95,2.7h-2.69v-2.7h8.09v2.7h-2.7v13.5h2.7v2.7h-8.09v-2.7h2.69v-13.5Zm11.65,13.5h-1.35v-13.5h1.35v-1.35h1.35v-1.35h8.1v1.35h1.35v1.35h1.35v13.5h-1.35v1.349h-1.35v1.35h-8.1v-1.35h-1.35v-1.349Zm8.1-13.5h-5.4v1.349h-1.35v10.8h1.35v1.35h5.4v-1.35h1.35v-10.8h-1.35v-1.349Zm11.71,16.2h-2.7v-18.9h2.7v1.35h1.35v2.7h1.35v1.349h1.35v2.7h1.35v1.349h1.35v2.7h1.35V244.926h2.7v18.9h-2.7v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v-1.35h-1.35v-2.7h-1.35v12.148ZM3325,232h-8v-4h8v4Zm-8,4h-8v-4h8v4Zm-8,8h-4v-8h4v8Zm-4,20h-4V244h4v20Zm20,12h-8v4h8v-4Zm-8-4h-8v4h8v-4Zm-8-8h-4v8h4v-8Zm188-32h8v-4h-8v4Zm8,4h8v-4h-8v4Zm8,8h4v-8h-4v8Zm4,20h4V244h-4v20Zm-20,12h8v4h-8v-4Zm8-4h8v4h-8v-4Zm8-8h4v8h-4v-8Zm-16-32H3325v-4h172v4Zm0,48H3325v-4h172v4Z"
                    transform="translate(-3301 -228)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="82"
                  height="26"
                  viewBox="0 0 82 26"
                >
                  <path
                    id="casting"
                    class="cls-1"
                    d="M268.274,306.561H267.6v-6.748h0.676v-0.675h0.674v-0.674H273v0.674h0.675v0.675h0.675v1.35H273v-0.675h-0.675v-0.675h-2.7v0.675h-0.676v5.4h0.676v0.675h2.7v-0.675H273v-0.674h1.35v1.349h-0.675v0.675H273v0.675h-4.049v-0.675h-0.674v-0.675Zm14.556-2.7h0.675v4.049h-1.35v-2.025h-4.048v2.025h-1.35v-4.049h0.676v-1.35h0.674v-1.349h0.674v-1.35h0.675v-1.349h1.349v1.349h0.675v1.35h0.675v1.349h0.675v1.35Zm-4.049.675h2.7v-1.35h-0.675v-2.024h-1.349v2.024h-0.675v1.35Zm11.859-4.724h-2.7v0.675h-0.675v1.35h0.675v0.674h3.374v0.675h0.676v0.675h0.674v2.7H291.99v0.675h-0.676v0.675h-4.049v-0.675H286.59v-0.675h-0.675v-1.349h1.35v0.674h0.675v0.675h2.7v-0.675h0.674v-1.349H290.64v-0.675h-3.375v-0.675H286.59v-0.675h-0.675v-2.7h0.675v-0.675h0.675v-0.674h4.049v0.674h0.676v0.675h0.674v1.35h-1.35v-0.675H290.64v-0.675Zm10.854-1.349v1.349h-2.7v8.1h-1.349v-8.1h-2.7v-1.349h6.748Zm3.423,1.349h-1.35v-1.349h4.05v1.349h-1.35v6.748h1.35v1.35h-4.05v-1.35h1.35v-6.748Zm6.446,8.1h-1.35v-9.447h1.35v0.674h0.674v1.35h0.676v0.675h0.674v1.349h0.674v0.675h0.676v1.35h0.674v-6.073h1.349v9.447h-1.349v-0.675h-0.674v-1.35h-0.676v-0.674h-0.674v-1.35h-0.674v-0.675h-0.676v-1.349h-0.674v6.073Zm8.5-1.35H319.19v-6.748h0.674v-0.675h0.676v-0.674h4.048v0.674h0.675v0.675h0.674v1.35h-1.349v-0.675h-0.674v-0.675h-2.7v0.675H320.54v5.4h0.674v0.675h2.7v-0.675h0.674v-2.024h-2.024v-1.35h3.373v4.049h-0.674v0.675h-0.675v0.675H320.54v-0.675h-0.676v-0.675ZM268,292h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V298h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm66-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V298h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8,8H268v-2h58v2Zm0-24H268v-2h58v2Z"
                    transform="translate(-256 -290)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="118"
                  height="26"
                  viewBox="0 0 118 26"
                >
                  <path
                    id="development"
                    d="M2525.67,346.562v0.674H2525v0.675h-5.4v-9.448h5.4v0.675h0.67v0.675h0.68v6.749h-0.68Zm-1.35-6.749h-3.37v6.749h3.37v-0.676H2525v-5.4h-0.68v-0.675Zm4.48-1.35h6.08v1.35h-4.73v2.7h3.38v1.349h-3.38v2.7h4.73v1.349h-6.08v-9.448Zm9.18,2.7h-0.67v-2.7h1.35v2.025h0.67v2.024h0.68v2.024h1.35v-2.024h0.67v-2.024h0.67v-2.025h1.35v2.7h-0.67v2.024h-0.68v2.024h-0.67v1.351h-0.67v1.349h-1.35v-1.349h-0.68v-1.351h-0.67v-2.024h-0.68v-2.024Zm8.51-2.7h6.07v1.35h-4.72v2.7h3.38v1.349h-3.38v2.7h4.72v1.349h-6.07v-9.448Zm8.51,0h1.35v8.1h4.72v1.349H2555v-9.448Zm8.84,8.1h-0.67v-6.749h0.67v-0.675h0.68v-0.675h4.05v0.675h0.67v0.675h0.68v6.749h-0.68v0.674h-0.67v0.675h-4.05v-0.675h-0.68v-0.674Zm4.05-6.749h-2.7v0.675h-0.67v5.4h0.67v0.676h2.7v-0.676h0.68v-5.4h-0.68v-0.675Zm10.56,2.7v0.675h-0.68v0.674h-4.05v4.05h-1.35v-9.448h5.4v0.675h0.68v0.675h0.67v2.7h-0.67Zm-1.35-2.7h-3.38v2.7h3.38v-0.675h0.67v-1.349h-0.67v-0.675Zm6.48,2.7h-0.67v5.4h-1.35v-9.448h1.35v1.35h0.67v1.35h0.68v1.349h1.35v-1.349h0.67v-1.35h0.68v-1.35h1.35v9.448h-1.35v-5.4h-0.68v1.349h-0.67v1.35h-1.35v-1.35h-0.68v-1.349Zm7.21-4.049h6.07v1.35h-4.72v2.7h3.37v1.349h-3.37v2.7h4.72v1.349h-6.07v-9.448Zm9.85,9.448h-1.35v-9.448h1.35v0.675h0.68v1.35h0.67v0.675h0.68v1.349h0.67v0.675h0.68v1.349h0.67v-6.073h1.35v9.448h-1.35v-0.675h-0.67v-1.35h-0.68v-0.675h-0.67v-1.35h-0.68v-0.674h-0.67v-1.35h-0.68v6.074Zm14.26-9.448v1.35h-2.7v8.1h-1.35v-8.1h-2.7v-1.35h6.75ZM2520,332h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V338h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm102-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V338h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16h-94v-2h94v2Zm0,24h-94v-2h94v2Z"
                    transform="translate(-2508 -330)"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="102"
                  height="26"
                  viewBox="0 0 102 26"
                >
                  <path
                    id="promotion"
                    d="M221.672,456.512v0.674H221v0.675h-4.048v4.05H215.6v-9.448H221v0.675h0.675v0.675h0.675v2.7h-0.675Zm-1.35-2.7h-3.373v2.7h3.373v-0.675H221v-1.35h-0.675v-0.674Zm9.32,6.749h-0.675v-0.675h-0.674v-0.675h-0.674v-0.675h-0.676v-0.676h-0.674v4.05h-1.35v-9.448h5.4v0.675h0.674v0.675h0.676v2.7h-0.676v0.674h-0.674v0.675h-1.35v0.676h0.675v0.675h0.675v0.675h0.674v0.675h0.676v1.349h-1.35v-0.675h-0.675v-0.674Zm0-6.749h-3.373v2.7h3.373v-0.675h0.675v-1.35h-0.675v-0.674Zm5.272,6.749h-0.675v-6.749h0.675v-0.675h0.675v-0.675h4.05v0.675h0.674v0.675h0.676v6.749h-0.676v0.674h-0.674v0.675h-4.05v-0.675h-0.675v-0.674Zm4.05-6.749h-2.7v0.674h-0.675v5.4h0.675v0.675h2.7v-0.675h0.675v-5.4h-0.675v-0.674Zm6.639,2.7h-0.675v5.4h-1.349v-9.448h1.349v1.35H245.6v1.35h0.675v1.349h1.349v-1.349H248.3v-1.35h0.675v-1.35h1.349v9.448h-1.349v-5.4H248.3v1.349h-0.676v1.351h-1.349v-1.351H245.6v-1.349Zm7.99,4.05h-0.675v-6.749h0.675v-0.675h0.675v-0.675h4.048v0.675h0.676v0.675h0.674v6.749h-0.674v0.674h-0.676v0.675h-4.048v-0.675h-0.675v-0.674Zm4.049-6.749h-2.7v0.674h-0.675v5.4h0.675v0.675h2.7v-0.675h0.674v-5.4h-0.674v-0.674Zm11.015-1.35v1.35h-2.7v8.1h-1.35v-8.1h-2.7v-1.35h6.746Zm3.587,1.35H270.9v-1.35h4.048v1.35h-1.35v6.749h1.35v1.349H270.9v-1.349h1.349v-6.749Zm5.93,6.749H277.5v-6.749h0.673v-0.675h0.676v-0.675H282.9v0.675h0.676v0.675h0.674v6.749h-0.674v0.674H282.9v0.675H278.85v-0.675h-0.676v-0.674Zm4.051-6.749h-2.7v0.674H278.85v5.4h0.676v0.675h2.7v-0.675H282.9v-5.4h-0.674v-0.674Zm5.965,8.1h-1.351v-9.448h1.351v0.675h0.675v1.349h0.675v0.676h0.674v1.349h0.675v0.674h0.674v1.351h0.676v-6.074h1.349v9.448h-1.349v-0.675h-0.676v-1.349h-0.674v-0.675h-0.675v-1.351H289.54v-0.675h-0.675v-1.349H288.19v6.074ZM216,446h-4v-2h4v2Zm-4,2h-4v-2h4v2Zm-4,4h-2v-4h2v4Zm-2,10h-2V452h2v10Zm10,6h-4v2h4v-2Zm-4-2h-4v2h4v-2Zm-4-4h-2v4h2v-4Zm86-16h4v-2h-4v2Zm4,2h4v-2h-4v2Zm4,4h2v-4h-2v4Zm2,10h2V452h-2v10Zm-10,6h4v2h-4v-2Zm4-2h4v2h-4v-2Zm4-4h2v4h-2v-4Zm-8-16H216v-2h78v2Zm0,24H216v-2h78v2Z"
                    transform="translate(-204 -444)"
                  />
                </svg>
              </li>
            </ul>
            <ul class="cases-header__nums">
              <li>
                <b>18</b>
                <span>{{ $t('other.million') }}</span>
                <p>{{ $t('other.coverage') }}</p>
              </li>
              <li>
                <b>40</b>
                <span>{{ $t('other.thousands') }}</span>
                <p>{{ $t('other.members') }}</p>
              </li>
            </ul>
          </div>
          <div class="cases-header__col-right">
            <div class="cases-header__desc" v-html="$t('boss.text_1')"></div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section class="boss-video">
        <div class="video-block video-block--black js-video-block">
          <video
            src="@/assets/video/boss.mp4"
            loop
            autoplay
            playsinline
            muted
            width="650"
            height="365"
          ></video>
          <div class="video-block__controls">
            <div class="video-block__controls-timeline">
              <!-- <span id="current-time">00:00</span>
							<span id="total-time">01:00</span> -->
              <div class="progress">
                <div class="progress-passed"></div>
              </div>
            </div>
            <div class="video-block__controls-buttons">
              <span id="play-pause">play</span>
              <span id="mute-unmute">sound off</span>
              <!-- <span id="close" class="hidden">close</span> -->
            </div>
          </div>
        </div>
      </section>
      <section class="boss-interactive" id="app">
        <div class="container">
          <div class="boss-interactive__wr">
            <div class="ball-game">
              <div class="ball-game__count">
                <b>{{ count }}</b>
                <span>{{ $t('boss.score') }}</span>
              </div>
              <p
                v-if="!finish"
                class="ball-game__text"
                v-html="$t('boss.click')"
              ></p>
              <p
                v-else
                @click="reset"
                class="ball-game__text ball-game__text--repeat"
              >
                {{ $t('boss.repeatGame') }}
              </p>
              <div class="ball-game__field">
                <div
                  tabindex="-1"
                  @click="clickBall"
                  class="ball-game__ball js-ball"
                  ref="ball"
                  style="transform: translateY(0px) translateX(0px)"
                >
                  <span>
                    <img
                      src="@/assets/img/boss/ball.gif"
                      alt=""
                      width="40"
                      height="40"
                    />
                  </span>
                </div>
                <img
                  src="@/assets/img/boss/field.png"
                  alt=""
                  width="814"
                  height="171"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="boss-text">
        <div class="container">
          <div class="boss-text__wr">
            <h2>{{ $t('boss.rulesTitle') }}</h2>
            <p v-html="$t('boss.rulesText')"></p>
          </div>
        </div>
      </section>
    </main>
    <footer class="cases-footer">
      <div class="container">
        <div class="cases-footer__wr">
          <ul class="cases-footer__menu">
            <li>
              <router-link :glitch="$t('menu.agency')" to="/agency">{{
                $t('menu.agency')
              }}</router-link>
            </li>
            <li class="lang-offer-menu-2 js-lang-offer-menu">
              <router-link :glitch="$t('menu.cases')" to="/cases">{{
                $t('menu.cases')
              }}</router-link>
            </li>
            <li>
              <router-link :glitch="$t('menu.contacts')" to="/contacts">{{
                $t('menu.contacts')
              }}</router-link>
            </li>
          </ul>
          <img src="@/assets/img/agency/mouse.gif" alt="" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  data() {
    return {
      animationTop: null,
      animationBottom: null,
      animationTopSecond: null,
      finish: false,
      canBeClick: false,
      count: 0,
      x: 0,
      y: 0,
    }
  },
  methods: {
    updatePosition() {
      const position = $(this.$refs.ball).css('transform')
      this.x = Math.abs(position.slice(7, -1).split(', ')[4])
      this.y = Math.abs(position.slice(7, -1).split(', ')[5])
    },
    clickBall() {
      this.updatePosition()
      if (!this.animationTop) {
        this.animeTop()
        return
      }
      if (this.finish || !this.canBeClick) return
      if (this.y > 10 && this.y < 300) {
        this.animeTopSecond()
      }
    },
    animeTop() {
      this.animationTop = anime({
        targets: this.$refs.ball,
        translateY: -250,
        duration: 2000,
        autoplay: false,
        easing: 'easeOutCubic',
        complete: (anim) => {
          this.updatePosition()
          this.animeBottom()
          this.canBeClick = true
        },
      })
      this.animationTop.play()
    },
    animeBottom() {
      const self = this
      this.direction = 'bottom'
      this.animationBottom = anime({
        targets: this.$refs.ball,
        translateX: [-self.x, 0],
        translateY: [-self.y, 0],
        duration: self.animationTopSecond ? 2500 : 2000,
        autoplay: true,
        easing: 'easeInCubic',
        complete: function (anim) {
          self.finish = true
          anime({
            targets: self.$refs.ball,
            translateX: [-self.x, 0],
            keyframes: [
              { scaleX: 1.25, duration: 75, easing: 'easeOutCubic' },
              { scaleX: 1, duration: 75, easing: 'easeOutCubic' },
              { translateY: -90, duration: 250, easing: 'easeOutCubic' },
              { translateY: 0, duration: 500, easing: 'easeOutBounce' },
            ],
            autoplay: true,
          })
        },
        update: function (anim) {
          self.updatePosition()
        },
      })
      this.animationBottom.play()
    },
    animeTopSecond() {
      this.count++
      this.canBeClick = false
      this.animationTop.remove(this.$refs.ball)
      if (this.animationBottom) {
        this.animationBottom.remove(this.$refs.ball)
      }
      const self = this
      this.animationTopSecond = anime({
        targets: this.$refs.ball,
        translateY: -this.y + -(Math.random() * 100 + 100),
        duration: 1500,
        autoplay: false,
        easing: 'easeOutCubic',
        complete: function (anim) {
          self.updatePosition()
          self.animeBottom()
          self.canBeClick = true
        },
      })
      this.animationTopSecond.play()
    },
    reset() {
      this.animationTop = null
      this.animationBottom = null
      this.finish = false
      this.count = 0
      this.x = 0
      this.y = 0
    },
  },
  mounted() {
    oldCode()
  },
}

const oldCode = () => {
  $('.js-video-block').each((index, item) => {
    const video = $(item).find('video')[0]
    const playBtn = $(item).find('#play-pause')
    const controls = $(item).find('.video-block__controls')
    const muteBtn = $(item).find('#mute-unmute')
    const progressBar = $(item).find('.progress-passed')
    let videoPlayed = $(video).attr('autoplay')
    let videoMuted = $(video).attr('muted')
    let showControlsTimer
    playBtn.html(videoPlayed ? 'pause' : 'play')
    muteBtn.html(videoMuted ? 'sound off' : 'sound on')

    $(item).on({
      mouseenter: function () {
        controls.fadeIn(300)
      },
      mouseleave: function () {
        controls.fadeOut(300)
        clearTimeout(showControlsTimer)
      },
    })

    $(item).mousemove(function (event) {
      controls.fadeIn(300)
      clearTimeout(showControlsTimer)
      showControlsTimer = setTimeout(() => {
        controls.fadeOut(300)
      }, 2000)
    })

    playBtn.on('click', function (e) {
      if (!videoPlayed) {
        videoPlayed = true
        video.play()
      } else {
        videoPlayed = false
        video.pause()
      }
      playBtn.html(videoPlayed ? 'pause' : 'play')
    })

    muteBtn.on('click', function (e) {
      videoMuted = !videoMuted
      muteBtn.html(videoMuted ? 'sound off' : 'sound on')
      video.muted = !video.muted
    })

    $(video).on('timeupdate', function (event) {
      const current = this.currentTime
      const percent = this.duration / 100
      const progress = (current / percent).toFixed()
      progressBar.css('width', progress + '%')
    })
  })
}
</script>

<style></style>

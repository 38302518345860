export default {
  ru: {
    menu: {
      menu: 'Меню',
      agency: 'Контент-агентство',
      cases: 'Кейсы',
      contacts: 'Контакты',
      lang: 'eng',
    },
    cookies: {
      text: 'Используя этот сайт, вы соглашаетесь на использование файлов cookie',
    },
    contacts: {
      city: 'Москва',
      address: 'ул. Гиляровского, д. 4 стр. 5, офис 407',
    },
    cases: {
      taro: {
        title: 'Бьюти-таро',
        desc: 'Rimmel',
      },
      lacoste: {
        title: 'счастье быть собой',
        desc: 'lacoste',
      },
      france: {
        title: 'ГОРЫ ЛЕТОМ',
        desc: 'France.fr',
      },
      hugo: {
        title: 'I’M HUGO',
        desc: 'HUGO Boss',
      },
      elovena: {
        title: 'Без глютена',
        desc: 'ELOVENA',
      },
      boss: {
        title: '#SUITCHALLENGE',
        desc: 'boss',
      },
    },
    other: {
      publications: 'публикаций',
      coverage: 'охват',
      million: 'млн',
      subscribers: 'подписчики',
      members: 'участники',
      thousands: 'тыс.',
      presentation: 'презентация',
      showreel: 'шоурил',
    },
    taro: {
      title: 'Бьюти-таро',
      desc: 'Rimmel',
      click: 'Кликни по карте',
      clickMore: 'Еще карту',
      clickMoreCards: 'показать ещё',
      text_1: `Осенью 2019 года бренд Rimmel провел рекламную кампанию, посвященную празднику
      Halloween. Перед нами стояла задача вдохновить девушек сделать праздничный макияж с&nbsp;помощью нюдовых оттенков косметики, широко представленных в&nbsp;палитре бренда. В
      рамках креативной рассылки блогеры получили не&nbsp;только наборы косметики, но&nbsp;и&nbsp;колоды
      бьюти-таро с&nbsp;образами Rimmel.`,
      cards: {
        emperor: {
          title: 'Император',
          text: `Карта перфекциониста, который вечно стремится к&nbsp;тому, чтобы все улучшать до
          бесконечности. Пока другие пытаются положиться на&nbsp;вас, положитесь на&nbsp;широкую палитру
          лаков. С&nbsp;ней вы&nbsp;точно подберете подходящий оттенок маникюра к&nbsp;вашему образу. Однако
          не&nbsp;переусердствуйте с&nbsp;красками, не&nbsp;позволяйте эмоциям резонировать. Сохраняйте ум
          трезвым и&nbsp;продолжайте бить в&nbsp;одну точку, пока не&nbsp;добьетесь своего.`,
        },
        devil: {
          title: 'Дьявол',
          text: `Вам следует быть осмотрительнее во&nbsp;всем. Чтобы другие вас не&nbsp;обжигали и&nbsp;не&nbsp;обижали,
          попробуйте сегодня броский образ. Для его создания вам пригодится палетка теней для век
          Rimmel Magnif&rsquo;eyes и&nbsp;темная помада Rimmel Stay Satin. Постарайтесь не&nbsp;совершать
          действий, которые вам навязывают другие. Но&nbsp;и&nbsp;не&nbsp;пытайтесь держать под контролем всех
          остальных. Слушайте себя и&nbsp;следите за&nbsp;собой.`,
        },
        moon: {
          title: 'Луна',
          text: `Самая романтичная и&nbsp;мечтательная карта. Не&nbsp;бойтесь своей темной стороны, позвольте
          всему непонятному и&nbsp;невидимому произойти с&nbsp;вами. Используйте переливающуюся
          подводку-тени для век Rimmel Wonder&rsquo;Swipe и&nbsp;блеск для губ Rimmel Oh&nbsp;My&nbsp;Gloss, чтобы
          освещать себе путь к&nbsp;мечтам. Доверяйте подсознанию и&nbsp;не&nbsp;пытайтесь найти всему
          рациональное объяснение`,
        },
        death: {
          title: 'Смерть',
          text: `Карта, напоминающая вам о&nbsp;том, что все имеет свой конец. Не&nbsp;бойтесь попрощаться с&nbsp;чем-то или кого-то отпустить. На&nbsp;пустое место придет что-то новое. Позвольте себе выглядеть
          ярко со&nbsp;стойкой помадой Rimmel Stay Satin, чтобы ни&nbsp;у&nbsp;кого не&nbsp;осталось сомнений о&nbsp;вашем
          решительном настрое.`,
        },
        fortune: {
          title: 'Колесо фортуны',
          text: `С&nbsp;этого момента в&nbsp;вашей жизни все начнет меняться в&nbsp;лучшую сторону. Доверьтесь судьбе
          в&nbsp;главном, а&nbsp;в&nbsp;макияже положитесь на&nbsp;палетку теней для век Rimmel Magnif&rsquo;eyes. Не&nbsp;теряйтесь в&nbsp;выборе цвета, используйте хоть всю дюжину! Не&nbsp;пытайтесь чему-то
          противиться, дайте колесу крутиться и&nbsp;катить вас в&nbsp;интересное яркое будущее.`,
        },
      },
      subTitle: 'Решение <br> на темной стороне',
      subDesc: `Чтобы пробудить мистическое настроение, мы&nbsp;создали реальную колоду таро с&nbsp;бьютипредсказаниями, а&nbsp;в&nbsp;образах старшего аркана визуализировали возможные варианты
      мейкапа. Описания карт составила астролог и&nbsp;журналистка Оля Осипова, автор проекта
      Ретроградный Меркурий.`,
      contentTitle: 'Публикация контента ',
      contentDesc: `Блогеры создали множество креативных образов. Каждый разместил в&nbsp;cоцсетях серию
      публикаций: анонсы в&nbsp;сторис, гадания в&nbsp;прямом эфире, how-to видео демонстрирующие
      процесс создания мейкапов с&nbsp;помощью косметики Rimmel.
      Идея оказалась настолько вовлекающей, что некоторые блогеры время от&nbsp;времени
      возвращались к&nbsp;бьюти-гаданиям на&nbsp;картах таро. 
      `,
      unboxing: 'Распаковка',
      divination: 'Гадание',
      makeup: 'Макияж',
    },
    lacoste: {
      title: 'Счастье <br> быть собой',
      desc: 'lacoste',
      text_1: `Харизматичные героини кампании&nbsp;&mdash; режиссёр, актриса и&nbsp;сценарист Рената Литвинова,
      чиллософ и&nbsp;писатель Ирина Хакамада, актриса Равшана Куркова&nbsp;&mdash; поделились своим
      видением красоты и&nbsp;примерили аромат Lacoste Pour Femme, представляющий собой базу
      парфюмерного гардероба и&nbsp;французский шик на&nbsp;каждый день.`,
      subTitle: 'Уникальный кастинг ',
      text_2: `Привлечение ярких и&nbsp;характерных женщин для участия в&nbsp;проекте стало основой успеха
      кампании. Все они одинаково широко известны. При этом их&nbsp;аудитории пересекаются
      всего на&nbsp;10%, будучи практически идентичными по&nbsp;социально-демографическим
      показателям.`,
      name_1: 'Рената Литвинова',
      quote_1: `У&nbsp;красоты много лиц и&nbsp;мое&nbsp;&mdash; это вечная классика, в&nbsp;которой ты&nbsp;можешь быть прекрасна
      по&#8209;разному.`,
      name_2: 'Ирина Хакамада',
      quote_2: `Модно&nbsp;то, в&nbsp;чем выражается личность. Вещь выходит из&nbsp;моды, личность&nbsp;&mdash; нет.`,
      name_3: 'Равшана Куркова',
      quote_3: `Гармония внутри меня и&nbsp;она помогает мне видеть красоту момента.`,
      titleBottom: `Всюду звучал Lacoste Pour Femme`,
      textBottom_1: `Героини блистали различными гранями женственности и&nbsp;обаяния, демонстрируя и&nbsp;подтверждая тем самым, что для красоты достаточно просто быть собой.`,
      textBottom_2: `Запуск включал три составляющие: вдохновляющую, информационную и&nbsp;вовлекающую
      &mdash;&nbsp;имиджевые видео, продуктовые посты с&nbsp;анонсом промоакции, сторис с&nbsp;call-to-action.
      Также были задействованы in-store поверхности в&nbsp;сети Рив Гош.`,
    },
    france: {
      title: 'ГОРЫ ЛЕТОМ',
      desc: 'France.fr',
      text_1: `Каждому хотелось&nbsp;бы, чтобы отпуск прошел на&nbsp;высоте, но&nbsp;летом немногие задумываются
      о&nbsp;поездке в&nbsp;горы. Да&nbsp;и&nbsp;что там делать?`,
      question_1: 'Итак, лето в&nbsp;Альпах, с&nbsp;чего начнете? ',
      question_2: 'А потом?',
      question_3: 'Чем займетесь теперь?',
      question_4: 'Как насчет?',
      question_5: 'И напоследок',
      gallery: {
        2: 'Рейсовый пароход Belle Époque',
        3: ' Причал в Эвиане',
        4: 'Парусник в Женевском озере',
        5: 'Капитан, Анси',
        6: '«Сад пяти чувств» в Ивуаре',
        7: 'Анси – альпийская Венеция',
        8: 'Замок Ментон-Сен-Бернар',
        9: 'Улицы Эвиана',
        10: 'Витрина французских сыров',
        11: 'Альпийское ассорти',
        12: 'Проба степени зрелости сыра',
        13: 'Наедине со вкусом',
        14: 'Французские Альпы, Шамони',
        15: '«Балкон» Европы',
        16: 'Смотровая площадка Монблан',
        17: 'Смотровая площадка Монблан',
        18: 'Мер-де-Глас – крупнейший ледник Франции',
        19: 'Мер-де-Глас – крупнейший ледник Франции',
        20: ' Горнолыжный курорт Шамони-МонБлан',
        21: 'Канатная дорога, Морзин',
        22: 'Озеро Монрион, Морзин',
        23: 'Озеро Монрион, Морзин',
        24: ' Пляж в Эвиане',
        25: 'Площадка для парапланов',
        26: 'Зона катания на маунтинбайке Порт- дю-Солей, Морзин',
        27: 'Набережная в Анси',
      },
      subTitle: 'И&nbsp;в&nbsp;пир и&nbsp;в&nbsp;мир',
      text_2: `Мы&nbsp;пополнили фотобанк офиса по&nbsp;туризму Франции на&nbsp;тысячу фотографий и&nbsp;около сотни
      видео футажей. Во&nbsp;время экспедиции во&nbsp;Францию запустили кампанию в&nbsp;сторис:
      делились вдохновляющими видами, впечатлениями, обзорами гостиниц и&nbsp;помогали
      сориентироваться в&nbsp;стоимости местных продуктов или услуг. А&nbsp;на&nbsp;страницах в&nbsp;соцсетях
      офиса по&nbsp;туризму рассказали о&nbsp;множестве возможностей развлечься и&nbsp;отдохнуть в&nbsp;горах
      летом.`,
    },
    hugo: {
      title: 'I’M HUGO',
      desc: 'HUGO Boss',
      text_1: `Анонсировали выход новой коллекции: привлекли для участия в&nbsp;кампании разноплановых
      героев, которых, по&nbsp;сути, объединял только хайп вокруг событий в&nbsp;их&nbsp;творческих жизнях
      (выход фильма, новых альбомов) и&nbsp;сняли lookbook контент для Hugo.`,
      name_1: {
        name: 'Даша <br>Чаруша',
        role: 'режиссёр',
      },
      name_2: {
        name: 'ева <br>Гурари',
        role: 'певица',
      },
      name_3: {
        name: 'Роман <br>Варнин',
        role: 'Музыкант',
      },
      subTitle_1: 'КОЛЛЕКЦИЯ ВДОХНОВЛЕНА MUSEUMSINSEL В&nbsp;БЕРЛИНЕ ',
      subTitle_2: 'На стыке моды и&nbsp;искусства ',
      subText_2: `Вещи из&nbsp;новой коллекции HUGO BOSS&nbsp;&mdash; это инструменты выражения себя. Искусство
      вышло на&nbsp;улицы больших городов, оно повсюду и&nbsp;каждое утро, выбирая, что надеть, мы&nbsp;и
      творцы, и&nbsp;арт-произведения.`,
    },
    elovena: {
      title: 'Без глютена',
      desc: 'ELOVENA',
      text_1: `Финский бренд безглютеновых продуктов из&nbsp;овса Elovena мы&nbsp;сделали настоящим
      микроифлюенсером в&nbsp;узком сегменте специального диетического питания. Страница
      Elovena&nbsp;&mdash; это не&nbsp;просто блог рецептов, а&nbsp;gluten-free коммьюнити.`,
      question_1_title: 'Что не переносят люди с&nbsp;целиакией?',
      question_1_answer:
        'Правильный ответ&nbsp;&mdash; глютен, поэтому в&nbsp;продуктах Elovena польза есть, а&nbsp;глютена нет. Идеально в&nbsp;любой день!',
      question_1_answers: ['Глютен', 'Сахар', 'Утро понедельника'],
      question_2_title: 'А&nbsp;что, кроме чеснока, не&nbsp;содержит глютен?',
      question_2_answer: 'У&nbsp;Elovena всё-всё без глютена',
      question_2_answers: ['Мука', 'Овсяные хлопья', 'Галеты'],
      question_3_title: 'На&nbsp;безглютеновой диете сложнее всего без..?',
      question_3_answer:
        'С&nbsp;безглютеновыми продуктами Elovena заботиться о&nbsp;здоровье гораздо проще.',
      question_3_answers: ['Печенья', 'Хлеба', 'Блинов'],
      question_4_title:
        'Что&nbsp;бы такого съесть, чтобы глютена не&nbsp;было, а&nbsp;клетчатка была?',
      question_4_answer:
        'У&nbsp;нас всё без глютена, а&nbsp;клетчатки больше всего в&nbsp;овсяных хлопьях.',
      question_4_answers: ['Овсяные хлопья', 'Каша минутка ', 'Лимонный пирог'],
      question_5_title: 'Что возьмешь с&nbsp;собой на&nbsp;пикник?',
      question_5_answer:
        'Можно не&nbsp;выбирать и&nbsp;взять всё, когда и&nbsp;печенье, и&nbsp;мука без глютена.',
      question_5_answers: [
        'Испеку что-нибудь',
        'Печенье с полки',
        'Книгу, я не ем глютен',
      ],
      socialTitle: 'СОЦСЕТИ',
      socialText: `На&nbsp;территории бренда в&nbsp;соцсетях пользователи, столкнувшиеся с&nbsp;такой редкой
      проблемой, как непереносимость глютена, могут делиться личным опытом и&nbsp;получать
      советы от&nbsp;врачей и&nbsp;нутрициологов. К&nbsp;сотрудничеству привлечены фуд-блогеры, эксперты, диетологи и&nbsp;даже селебрити.
      `,
    },
    boss: {
      title: '#SUITCHALLENGE',
      desc: 'boss',
      text_1: `Артем Дзюба, лучший игрок сезона и&nbsp;капитан сборной России по&nbsp;футболу 2021, принял
      передачу от&nbsp;звезд мирового футбола и&nbsp;присоединился к&nbsp;глобальному #SuitChallenge в&nbsp;поддержку аромата Boss Bottled.`,
      click: 'КЛИКАЙ ПО МЯЧУ, <br> НЕ ДАЙ ЕМУ УПАСТЬ! ',
      rulesTitle: `Правила игры`,
      rulesText: `Чтобы принять участие в&nbsp;конкурсе, пользователям необходимо было поделиться в&nbsp;соцсетях видео, где они чеканят мяч в&nbsp;деловом костюме. Главный приз&nbsp;&mdash; совместная
      тренировка с&nbsp;Артемом на&nbsp;футбольном поле.`,
      repeatGame: 'Играть еще раз',
      score: 'твой счет',
    },
    agency: {
      text_1: `Мы&nbsp;агентство со&nbsp;свободным взглядом на&nbsp;контент.`,
      text_2: `Соединяем реальные эмоции с&nbsp;диджитал-опытом, а&nbsp;бренды&nbsp;&mdash; с&nbsp;аудиторией. Для наших идей нет онлайн- и&nbsp;офлайн-рамок, кроме этики и&nbsp;человеческих возможностей.`,
      title_1: 'КРЕАТИВ И&nbsp;СТРАТЕГИЯ',
      subTitle_1:
        'Помогаем определить концептуальную платформу для достижения бизнес-целей. А&nbsp;каждый проект наделяем смыслами, органично встраиваемыми в&nbsp;повседневность потребителей. Мы&nbsp;хотим, чтобы ваш бренд не&nbsp;только успевал за&nbsp;трендами, но&nbsp;и&nbsp;задавал&nbsp;их.',
      title_2: 'ИНФЛЮЕНС-МАРКЕТИНГ',
      subTitle_2:
        'Выстраиваем взаимоотношения бренда с&nbsp;лидерами мнений и&nbsp;уверенно находим баланс между ценностями компании и&nbsp;имиджем инфлюенсера. Ориентируемся на&nbsp;принципы душевного менеджмента, не&nbsp;забывая о&nbsp;перформансе и&nbsp;правовых нюансах.',
      title_3: 'КОМЬЮНИТИ-МЕНЕДЖМЕНТ И&nbsp;SMM',
      subTitle_3:
        'Системно превращаем страницы брендов в&nbsp;центры притяжения для аудитории&nbsp;&mdash; в&nbsp;сообщества, где контент приятно смотреть и&nbsp;читать. Мы&nbsp;умеем быть ближе к&nbsp;подписчикам, выбирать подходящие форматы и&nbsp;каналы для продвижения информации.',
      title_4: 'ПРОДАКШН',
      subTitle_4:
        'Облекаем замысел в&nbsp;запоминающиеся визуальные образы. Транслируем идеи через различные фото- и&nbsp;видеоформы&nbsp;&mdash; от&nbsp;рекламных имиджей и&nbsp;роликов до&nbsp;нативных публикаций в&nbsp;социальных сетях. Мы&nbsp;знаем, как воплотить дух бренда в&nbsp;оформлении сайтов и&nbsp;дизайне.',
      textBottom: `Мы&nbsp;сотрудничаем с&nbsp;модным домом <strong>HUGO BOSS</strong>, разрабатываем кампании по&nbsp;продвижению ароматов <strong>Lacoste</strong>, <strong>CK</strong>, <strong>Gucci</strong> и&nbsp;декоративной косметики <strong>Bourjois</strong>, <strong>Rimmel</strong>, <strong>Max Factor</strong>, реализуем проекты для ухаживающих средств <strong>Innisfree</strong> и&nbsp;<strong>Lancaster</strong> и&nbsp;расширяем границы креатива вместе с&nbsp;туристическими офисами <strong>Франции</strong>, <strong>Швейцарии</strong>, <strong>Макао</strong>.`,
    },
  },
  en: {
    menu: {
      menu: 'menu',
      agency: 'content agency',
      cases: 'cases',
      contacts: 'contacts',
      lang: 'rus',
    },
    cookies: {
      text: 'By continuing to browse the site, you agree to our use of cookies.',
    },
    contacts: {
      city: 'MOSCOW',
      address: '4 Gilyarovskogo St, Building 5, Office 407',
    },
    cases: {
      taro: {
        title: 'Beauty tarot',
        desc: 'Rimmel',
      },
      lacoste: {
        title: 'The happiness of being myself',
        desc: 'lacoste',
      },
      france: {
        title: 'Summer mountains',
        desc: 'France.fr',
      },
      hugo: {
        title: 'I’M HUGO',
        desc: 'HUGO Boss',
      },
      elovena: {
        title: 'Gluten free',
        desc: 'ELOVENA',
      },
      boss: {
        title: '#SUITCHALLENGE',
        desc: 'boss',
      },
    },
    other: {
      publications: 'publications',
      coverage: 'coverage',
      million: 'm',
      subscribers: 'subscribers',
      members: 'members',
      thousands: 'k',
      presentation: 'presentation',
      showreel: 'showreel',
    },
    taro: {
      title: 'Beauty tarot',
      desc: 'Rimmel',
      click: 'Click on the card',
      clickMore: 'another card',
      clickMoreCards: 'show more',
      text_1: `In the fall of 2019, the Rimmel brand launched an advertising campaign dedicated to the
      Halloween holiday. Our task was to inspire girls to make a festive makeup with nude shades of
      cosmetics, widely rep resented in the brand's palette. As part of the creative distribution,
      bloggers received not only sets of cosmetics but also beauty tarot decks with Rimmel images.`,
      cards: {
        emperor: {
          title: 'Emperor',
          text: `The card of a perfectionist who is always striving to improve everything over and over again. While
          others are trying to rely on you, rely on a wide palette of nail polishes. You will definitely choose
          the right shade of manicure for your image. However, do not overdo it with colors, do not let
          emotions take over. Keep calm and keep hitting one point until you get your way.`,
        },
        devil: {
          title: 'Devil',
          text: `You should be more careful. Try a catchy look today and nobody burns or hurts you. To create it,
          you need a Rimmel Magnif' eyes eyeshadow palette and a Rimmel Stay Satin dark lipstick. Try to
          avoid imposed actions. But don 't try to keep everyone under control either. Listen to yourself and
          control yourself.`,
        },
        moon: {
          title: 'Moon',
          text: `The most romantic and dreamy card. Don't be afraid of your dark side. Let everything
          incomprehensible and invisible happen to you. Use iridescent Rimmel Wonder'swipe eyeliner and
          Rimmel Oh My Gloss lip Gloss to light your way to wishes. Trust your subconscious and don't try
          to explain everything`,
        },
        death: {
          title: 'Death',
          text: `A card that reminds you that everything has an end. Don't be afraid to say goodbye to something
          or let someone go. Something new will come to an empty place. Allow yourself to look bright with
          a stubborn lipstick Rimmel Stay Satin, so that no one has any doubts about your determined
          attitude.`,
        },
        fortune: {
          title: 'Wheel of Fortune',
          text: `Everything in your life is changing for the better from now on. Let the main things up to fate and,
          as for makeup, rely on the Rimmel Magnif' eyes eyeshadow palette. Don't get lost in choosing a
          color, use at least the whole dozen! Don't resist something, let the wheel turn and take you to an
          interesting bright future.`,
        },
      },
      subTitle: 'The solution <br> is on the dark side',
      subDesc: `To awaken a mystical mood, we created a real tarot deck with entertaining predictions and
      visualized possible makeup options in the images of the senior arcana. Astrologer and journalist
      Olya Osipova, author of the project Retrograde Mercury, compiled the descriptions of the cards.`,
      contentTitle: 'Content posting',
      contentDesc: `Bloggers have made a lot of creative images. Everyone posted a series of publications on
      Social Media: announcements in Stories, live divination, how-to videos or posts with a detailed
      description of creating a bright make-up using Rimmel cosmetics and a list of necessary
      products.
      The idea was so engaging that some bloggers were coming back to the beauty tarot divination
      from time to time.`,
      unboxing: 'Unboxing',
      divination: 'Divination',
      makeup: 'Makeup',
    },
    lacoste: {
      title: 'The happiness <br> of being myself',
      desc: 'lacoste',
      text_1: `The charismatic heroines of the campaign — the director, actress and screenwriter Renata
      Litvinova, chillosophist and writer Irina Khakamada, actress Ravshana Kurkova — shared their
      vision of beauty and tried on the Lacoste Pour Femme fragrance, which is the base of fragrance
      wardrobe and French chic for every day.`,
      subTitle: 'Unique casting',
      text_2: `The success of the campaign is based on the participation of bright and attractive women in the
      project. They are all equally well known. At the same time, their audiences overlap by only 10%,
      being almost identical in socio-demographic indicators.`,
      name_1: 'Renata Litvinova',
      quote_1: `Beauty has many faces and mine is a timeless classic, wearing which you can be beautiful in
      different ways.`,
      name_2: 'Irina Hakamada',
      quote_2: `Fashionable is how the personality expresses itself. The thing goes out of fashion, the
      personality does not.`,
      name_3: 'Ravshana Kurkova',
      quote_3: `My inner harmony helps me to notice beauty.`,
      titleBottom: `Lacoste Pour Femme was in the air and on the air`,
      textBottom_1: `The heroines shone with various sides of femininity and charm, demonstrating and thereby
      confirming that to be beautiful, it is enough just to be yourself.`,
      textBottom_2: `The launch included three parts: inspiring, informational and engaging — image videos, product
      posts with promotion announcement, Stories with call-to-action. In-store surfaces in the Rive
      Gauche stores were also involved.`,
    },
    france: {
      title: 'Summer mountains',
      desc: 'France.fr',
      text_1: `Everyone would like to spend a vacation on top, but few people think about going to the
      mountains in the summer. How to have fun there?`,
      question_1: 'So, it’s summertime in the Alps. What will you do first?',
      question_2: 'What will you choose after?',
      question_3: 'What will you do next?',
      question_4: 'What about these?',
      question_5: 'And finally?',
      gallery: {
        2: 'Liner Belle Époque',
        3: 'Berth in Evian',
        4: 'Sailboat in Lake of Geneva',
        5: 'Captain, Annecy',
        6: 'The Five Senses Garden in Ivorian',
        7: 'Annecy – Alpine Venice',
        8: 'Menton-Saint-Bernard Castle',
        9: 'The streets of Evian',
        10: 'French cheese at the storefront',
        11: 'Alpine mix',
        12: 'Determining cheese maturity',
        13: 'Tete-a-tete with savour',
        14: 'French Alps, Chamonix',
        15: 'The Balcony of Europe',
        16: 'Mont Blanc viewpoint',
        17: 'Mont Blanc viewpoint',
        18: 'Mer de Glace is the largest glacier of France',
        19: 'Mer de Glace is the largest glacier of France',
        20: 'Mont Blanc Ski Resort',
        21: 'Ropeway, Morzine',
        22: 'Monrion Lake , Morzine',
        23: 'Monrion Lake , Morzine',
        24: 'Monrion Lake , Morzine',
        25: 'Paragliding platform',
        26: 'Port du Soleil, mountain bike zone, Morzine',
        27: 'The embankment in Annecy',
      },
      subTitle: '"One-size-fits-all" content ',
      text_2: `As a result, it filled up the photo bank of the French tourism office with a thousand photos and
      about a hundred video footages.
      During the expedition to France we launched a campaign in Stories and shared inspiring views,
      impressions, reviews of hotels and helped to orient themselves in the cost of local products or
      services. Later we created posts about the many opportunities on how to have fun and relax in
      the mountains in summer on Social Media.`,
    },
    hugo: {
      title: 'I’M HUGO',
      desc: 'HUGO Boss',
      text_1: `We announced the release of a new collection by attracting various heroes to participate in the
      campaign and shot lookbook content for Hugo. The only connection between heroes was the
      hype around different events in their creative lives (the release of a movie or musical albums).`,
      name_1: {
        name: 'Dasha Charusha',
        role: 'producer',
      },
      name_2: {
        name: 'eva gurari',
        role: 'singer',
      },
      name_3: {
        name: 'roman varnin',
        role: 'Musician',
      },
      subTitle_1: 'collection is INSPIRED BY MUSEUMSINSEL IN BERLIN',
      subTitle_2: 'At the nexus of fashion and art',
      subText_2: `Clothes from the new HUGO BOSS collection are tools for expressing yourself. Art has taken to
      the streets of big cities, it’s everywhere and every morning, choosing what to wear, we are both
      creators and artworks.`,
    },
    elovena: {
      title: 'Gluten free',
      desc: 'ELOVENA',
      text_1: `We have made the Finnish brand of gluten-free oat products Elovena a real micro-influencer in
      the narrow segment of special dietary nutrition. The Elovena brand page on Social Media is not
      just a recipe blog, but a gluten-free community.`,
      question_1_title: `What do people suffering from celiac disease not tolerate?`,
      question_1_answer: `The correct answer is gluten, that's why there is benefit in Elovena products, but there is no
      gluten. Perfect for any day!`,
      question_1_answers: ['Gluten', 'Sugar', 'Monday morning'],
      question_2_title: 'And what else is gluten-free besides garlic?',
      question_2_answer: `All Elovena's products are gluten-free`,
      question_2_answers: ['Flour', 'Oat flakes', 'Biscuits'],
      question_3_title: 'What do you suffer the most by avoiding gluten ..?',
      question_3_answer:
        'Taking care of health is much easier with gluten-free Elovena products.',
      question_3_answers: ['Biscuits', 'Bread', 'Crepes'],
      question_4_title: 'What should I eat to get food fiber and avoid gluten?',
      question_4_answer:
        'Everything Elovenas is gluten-free and the most of food fibers are in the oat flakes.',
      question_4_answers: ['Oat flakes', 'Oat flakes', 'Lemon pie'],
      question_5_title: 'What will you take for a picnic?',
      question_5_answer: `Don't choose and take everything because it's gluten-free`,
      question_5_answers: [
        'What will you take for a picnic?',
        'Get yourself a cookie',
        `The book, I'm gluten intolerant.`,
      ],
      socialTitle: 'Social Media',
      socialText: `Users facing gluten intolerance share their personal experience and get advice from the doctors
      and nutritionists on the brand's Social media. Food bloggers, nutritionists and even celebrities
      are involved in cooperation.`,
    },
    boss: {
      title: '#SuitChallenge',
      desc: 'boss',
      text_1: `The best player of the season and captain of the Russia national football team 2021 Artem
      Dzyuba took the ball from the world’s football stars and joined the global #SuitChallenge to
      promote Boss Bottled perfume.`,
      click: `CLICK THE BALL, DON'T LET IT FALL!`,
      rulesTitle: `Rules of the game`,
      rulesText: `According to the rules of the contest, users should share a video of them juggling a
      ball while wearing a business suit. Grand prize is a joint training session with Artem on
      the football field.`,
      repeatGame: 'Play again',
      score: 'your score',
    },
    agency: {
      text_1: `Meet the agency with fairly relaxed and broad views about content.`,
      text_2: `We connect real emotions with digital experiences and brands with their audience. There are no online or offline borders for our ideas, except ethics and human capabilities.`,
      title_1: 'IDEA & STRATEGY',
      subTitle_1:
        'We are here to define a conceptual platform for achieving your business goals. Our projects get multiple meanings that suit the context and the consumers everyday life naturally. We want your brand not only to keep up with trends but also to start new outstanding ones.',
      title_2: 'INFLUENCER MARKETING',
      subTitle_2:
        'Let’s build warm relationships between your brand and opinion leaders together. Confidently finding a performance-epatage balance, we take advantage of thoughtful management but do not forget about legal nuances. The perfect match happens in Inbase.',
      title_3: 'SOCIAL MEDIA & COMMUNITY',
      subTitle_3:
        'We systematically turn brand pages into centers of attraction for the audience — into communities where content is pleasant to watch and read. We know how to be closer to followers and choose the appropriate formats and channels to make your information reach the minds.',
      title_4: 'PRODUCTION',
      subTitle_4:
        'We wrap projects in catchy visuals. We transmit ideas through various photo and video forms — from advertising images and commercials to native publications on social media. We know how to embody the spirit of your brand in designs and structure it on websites to solve your tasks.',
      textBottom: `We&nbsp;cooperate with the fashion house <strong>HUGO BOSS</strong>, develop campaigns to&nbsp;promote <strong>Lacoste</strong>, <strong>CK</strong>, <strong>Gucci</strong>
			perfumes and <strong>Bourjois</strong>, <strong>Rimmel</strong>, <strong>Max Factor</strong>, makeup products, implement projects for skincare brands 
			<strong>Innisfree</strong> and <strong>Lancaster</strong>. We&nbsp;also expand the boundaries of&nbsp;creativity together with the tourism offices of&nbsp;<strong>France</strong>, <strong>Switzerland</strong>, <strong>Macau</strong>.`,
    },
  },
}

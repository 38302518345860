import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import AgencyView from '../views/AgencyView.vue'
import CasesView from '../views/CasesView.vue'
import ContactsView from '../views/ContactsView.vue'

import BossView from '../views/cases/BossView.vue'
import TaroView from '../views/cases/TaroView.vue'
import LacosteView from '../views/cases/LacosteView.vue'
import FranceView from '../views/cases/FranceView.vue'
import HugoView from '../views/cases/HugoView.vue'
import ElovenaView from '../views/cases/ElovenaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
  },
  {
    path: '/agency',
    name: 'agency',
    component: AgencyView,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView,
  },
  {
    path: '/cases',
    name: 'cases',
    component: CasesView,
  },
  {
    path: '/cases/taro',
    name: 'taro',
    component: TaroView,
  },
  {
    path: '/cases/boss',
    name: 'boss',
    component: BossView,
  },
  {
    path: '/cases/lacoste',
    name: 'lacoste',
    component: LacosteView,
  },
  {
    path: '/cases/france',
    name: 'france',
    component: FranceView,
  },
  {
    path: '/cases/hugo',
    name: 'hugo',
    component: HugoView,
  },
  {
    path: '/cases/elovena',
    name: 'elovena',
    component: ElovenaView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
